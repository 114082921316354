import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Img from 'gatsby-image'
import * as React from 'react'
import styled from 'styled-components'
import type { ContentfulInfoBlock } from '../../generated/graphql-types'
import theme from '../atoms/theme'
import Button from '../molecules/button'
import {
  ContentBlockDescription,
  ContentBlockTitle,
  ContentBlockWrapper,
  HeadingWrapper,
} from '../molecules/content-blocks'

type InfoBlockProps = {
  block: ContentfulInfoBlock
}

const ContentWrapper = styled.div`
  display: grid;
  width: 100%;
  gap: 6rem;

  @media only screen and ${theme.breakpoints.fromMediumScreen} {
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  }
`

const Column = styled.div``

const InfoBlock = (props: InfoBlockProps): JSX.Element => {
  const { block } = props
  const { title, description, image, body, buttons } = block

  return (
    <ContentBlockWrapper>
      <HeadingWrapper>
        <ContentBlockTitle>{title}</ContentBlockTitle>
        <ContentBlockDescription>
          {documentToReactComponents(description.json)}
        </ContentBlockDescription>
      </HeadingWrapper>
      <ContentWrapper>
        {image?.fluid && (
          <Column>
            <Img fluid={image.fluid} alt={image.title} />
          </Column>
        )}
        {(body?.json || (buttons && buttons.length > 0)) && (
          <Column>
            {body?.json && documentToReactComponents(body.json)}
            {buttons && buttons.length > 0 && (
              <div>
                {buttons.map((button, index) => (
                  <Button
                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                    key={index}
                    visualStyle={
                      button.style && button.style === 'Primary'
                        ? 'Primary'
                        : 'Secondary'
                    }
                    visualType={
                      button.type && button.type === 'Solid'
                        ? 'Solid'
                        : 'Outline'
                    }
                    href={button.url}
                  >
                    {button.label}
                  </Button>
                ))}
              </div>
            )}
          </Column>
        )}
      </ContentWrapper>
    </ContentBlockWrapper>
  )
}

export default InfoBlock
