import * as React from 'react'
import styled from 'styled-components'
import theme from './theme'

interface OrderedListProps {
  list: Array<string>
}

const CustomOrderedList = styled.ol`
  list-style: none;
  counter-reset: coloured-square;
`

const CustomBulletPoint = styled.li`
  counter-increment: coloured-square;
  margin-bottom: 1rem;
  padding-left: 2.8rem;
  position: relative;

  &:before {
    content: counter(coloured-square) '';
    background: ${theme.colours.quaternary};
    height: 2rem;
    width: 2rem;
    text-align: center;
    font-family: fira-sans, sans-serif;
    font-size: 1.2rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
`

const OrderedList = (props: OrderedListProps): JSX.Element => {
  return (
    <CustomOrderedList>
      {props.list.map((item) => (
        <CustomBulletPoint key={item}>{item}</CustomBulletPoint>
      ))}
    </CustomOrderedList>
  )
}

export default OrderedList
