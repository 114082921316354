import React, { useRef, useState, ReactNode } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import styled from 'styled-components'
import theme from '../atoms/theme'

const ArrowSrc = require('../../assets/carousel-arrow.svg') as string

const StyledSlider = styled(Slider)`
  display: grid;

  .slick-dots {
    position: relative;
    bottom: -4rem;
    height: 4rem;
    li {
      transform: translateY(50%);
      width: ${(props: PagerProps): string =>
        props.alternative ? '1rem' : '2rem'};
    }
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const CarouselNav = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

interface ArrowProps {
  disabled?: boolean
}

const Arrow = styled.img`
  cursor: ${(props: ArrowProps): string =>
    props.disabled ? 'default' : 'pointer'};
  width: 4rem;
  height: 4rem;
  z-index: 2;
  opacity: ${(props: ArrowProps): string => (props.disabled ? '0.2' : '1')};
`

const PrevArrow = styled(Arrow)`
  transform: rotate(180deg);
`

const CarouselItem = styled.div`
  outline: none !important;
`

interface PagerProps {
  active?: boolean
  alternative?: boolean
}

export const Pager = styled.div`
  z-index: 2;
  width: ${(props: PagerProps): string => (props.active ? '1.6rem' : '1rem')};
  height: ${(props: PagerProps): string => (props.active ? '1.6rem' : '1rem')};
  transform: ${(props: PagerProps): string =>
    props.active
      ? 'translateY(-0.3rem) translateX(-0.1rem) rotate(45deg)'
      : 'rotate(45deg)'};
  background: ${(props: PagerProps): string =>
    props.active ? theme.colours.quaternary : theme.colours.gray1};
`

const AlternativePager = styled(Pager)`
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    transform: rotate(0deg);
    border-radius: 50%;
    width: 0.8rem;
    height: 0.8rem;
    border: 1px solid ${theme.colours.secondary};
    background: ${(props: PagerProps): string =>
      props.active ? theme.colours.secondary : theme.colours.white};
  }
`

interface Props {
  carouselItems: Array<ReactNode>
  alternative?: boolean
}

const Carousel = (props: Props): JSX.Element => {
  const [currentSlide, setCurrentSlide] = useState(0)

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (prev, next): void => {
      setCurrentSlide(next)
    },
    arrows: false,
    infinite: false,
    // eslint-disable-next-line react/display-name
    customPaging: (i: number): JSX.Element => {
      // eslint-disable-next-line react/prop-types
      return props.alternative ? (
        <AlternativePager active={i === currentSlide} />
      ) : (
        <Pager active={i === currentSlide} />
      )
    },
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
    ],
  }

  interface SliderType {
    current: {
      slickNext: () => void
      slickPrev: () => void
    }
  }
  const slider: SliderType = useRef()

  const handleArrow = (direction: string): void => {
    if (slider?.current) {
      switch (direction) {
        case 'next':
          slider.current.slickNext()
          break
        case 'prev':
          slider.current.slickPrev()
          break
        default:
          break
      }
    }
  }

  return (
    <Container>
      <StyledSlider {...settings} ref={slider}>
        {props.carouselItems.map((item, index) => {
          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
          return <CarouselItem key={index}>{item}</CarouselItem>
        })}
      </StyledSlider>
      {!props.alternative && (
        <CarouselNav>
          <PrevArrow
            disabled={currentSlide === 0}
            onClick={(): void => handleArrow('prev')}
            src={ArrowSrc}
            alt='Previous'
          />
          <Arrow
            disabled={currentSlide === props.carouselItems.length - 1}
            onClick={(): void => handleArrow('next')}
            src={ArrowSrc}
            alt='Next'
          />
        </CarouselNav>
      )}
    </Container>
  )
}

export default Carousel
