import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Close, Info } from '@material-ui/icons'
import * as contentful from 'contentful'
import React, { useEffect, useState } from 'react'
import { CookiesProvider, useCookies } from 'react-cookie'
import styled from 'styled-components'

import { Link } from 'gatsby'
import { Notification as NotificationType } from '../../models/notification'
import { BellAlert } from '../atoms/graphics/bell-alert'
import { Bolt } from '../atoms/graphics/bolt'
import { CalendarDays } from '../atoms/graphics/calendar-days'
import { Chat } from '../atoms/graphics/chat'
import { CursorArrowRipple } from '../atoms/graphics/cursor-arrow-ripple'
import { DocumentText } from '../atoms/graphics/document-text'
import { Envelope } from '../atoms/graphics/envelope'
import { ExclamationTriangle } from '../atoms/graphics/exclamation-triangle'
import { InformationCircle } from '../atoms/graphics/information-circle'
import { Megaphone } from '../atoms/graphics/megaphone'
import { PhoneArrowUpRight } from '../atoms/graphics/phone-arrow-up-right'
import { Sun } from '../atoms/graphics/sun'
import theme from '../atoms/theme'
import Button from '../molecules/button'

const { colours } = theme

type NotificationPropTypes = {
  isMenuActive?: boolean
}

const StyledALink = styled.a`
  display: block;
  width: 100%;
  flex-shrink: 0;
  @media only screen and ${theme.breakpoints.fromSmallScreen} {
    margin-left: 1rem;
    width: auto;
    display: inline-block;
  }
`

const StyledLink = styled(Link)`
  display: block;
  width: 100%;
  flex-shrink: 0;
  @media only screen and ${theme.breakpoints.fromSmallScreen} {
    margin-left: 1rem;
    width: auto;
    display: inline-block;
  }
`

const NotificationContainer = styled.div`
  background-color: ${colours.primary};
  padding: 2rem;
  background-color: ${(props): string =>
    props.colour ? props.colour : colours.primary};
  color: ${(props): string =>
    props.textColour ? props.textColour : colours.white};
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media only screen and ${theme.breakpoints.fromSmallScreen} {
    align-items: center;
    flex-direction: row;
  }

  @media only screen and ${theme.breakpoints.fromNormalScreen} {
    padding: 3.4rem;
  }

  p {
    margin: 0;
  }
`

const Notifications = styled.div`
  display: ${(props): string => (props.isMenuActive ? 'none' : 'block')};
`

const StyledClose = styled(Close)`
  cursor: pointer;
  font-size: 3.4rem;
  position: absolute;
  right: 1rem;
  @media only screen and ${theme.breakpoints.fromSmallScreen} {
    right: unset;
    position: relative;
    display: block;
  }
`

const NotificationContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: end;
  @media only screen and ${theme.breakpoints.fromSmallScreen} {
    margin: 0 2rem;
    align-items: center;
    flex-direction: row;
  }
`

const NotificationContentText = styled.div`
  flex-grow: 1;
`

const Icon = styled.div`
  flex-shrink: 0;
  width: 4rem;
  height: 4rem;
`

const Notification = (props: NotificationPropTypes): JSX.Element => {
  const [cookies, setCookie] = useCookies(['closedNotifications'])
  const [closedNotificationsArray, setClosedNotificationArray] = useState(
    cookies.closedNotifications || [],
  )
  const [notifications, setNotifications] = useState([])

  useEffect(() => {
    const client = contentful.createClient({
      space: process.env.GATSBY_CONTENTFUL_SPACE_ID,
      accessToken: process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN,
      environment: process.env.GATSBY_CONTENTFUL_ENVIRONMENT || 'master',
    })

    client
      .getEntries({
        // eslint-disable-next-line @typescript-eslint/camelcase
        content_type: 'notification',
      })
      .then((response) => setNotifications(response.items))
      .catch(console.error)
  }, [])

  const handleClose = (notificationID): void => {
    if (closedNotificationsArray.indexOf(notificationID) === -1) {
      setClosedNotificationArray((oldArray) => [...oldArray, notificationID])
      setCookie(
        'closedNotifications',
        JSON.stringify([...closedNotificationsArray, notificationID]),
        { path: '/' },
      )
    }
  }

  const renderNotifications = notifications.map(
    (notification: NotificationType) => {
      if (
        Array.isArray(closedNotificationsArray) &&
        closedNotificationsArray.length > 0 &&
        closedNotificationsArray.includes(notification.sys.id)
      ) {
        return null
      }

      let notificationColour = colours.darkGrey
      let notificationTextColour = colours.white
      let notificationIcon = <Info style={{ fontSize: '3.4rem' }} />

      switch (notification.fields.notificationType) {
        case 'General notice':
          notificationColour = colours.quaternary
          notificationTextColour = colours.white
          notificationIcon = (
            <Icon>
              <InformationCircle />
            </Icon>
          )
          break

        case 'Alert':
          notificationColour = colours.danger1
          notificationTextColour = colours.white
          notificationIcon = (
            <Icon>
              <ExclamationTriangle />
            </Icon>
          )
          break

        case 'Event':
          notificationColour = colours.secondary
          notificationTextColour = colours.white
          notificationIcon = (
            <Icon>
              <CalendarDays />
            </Icon>
          )
          break

        case 'Announcement':
          notificationColour = colours.secondary
          notificationTextColour = colours.white
          notificationIcon = (
            <Icon>
              <Megaphone />
            </Icon>
          )
          break

        case 'Update':
          notificationColour = colours.quaternary
          notificationTextColour = colours.white
          notificationIcon = (
            <Icon>
              <Bolt />
            </Icon>
          )
          break

        case 'News':
          notificationColour = colours.secondary
          notificationTextColour = colours.white
          notificationIcon = (
            <Icon>
              <DocumentText />
            </Icon>
          )
          break

        case 'Subscribe':
          notificationColour = colours.quaternary
          notificationTextColour = colours.white
          notificationIcon = (
            <Icon>
              <Envelope />
            </Icon>
          )
          break

        case 'Holidays':
          notificationColour = colours.quaternary
          notificationTextColour = colours.white
          notificationIcon = (
            <Icon>
              <Sun />
            </Icon>
          )
          break

        case 'Phone':
          notificationColour = colours.quaternary
          notificationTextColour = colours.white
          notificationIcon = (
            <Icon>
              <PhoneArrowUpRight />
            </Icon>
          )
          break

        case 'Social media':
          notificationColour = colours.quaternary
          notificationTextColour = colours.white
          notificationIcon = (
            <Icon>
              <CursorArrowRipple />
            </Icon>
          )
          break

        case 'Chat':
          notificationColour = colours.quaternary
          notificationTextColour = colours.white
          notificationIcon = (
            <Icon>
              <Chat />
            </Icon>
          )
          break

        case 'Reminder':
          notificationColour = colours.secondary
          notificationTextColour = colours.white
          notificationIcon = (
            <Icon>
              <BellAlert />
            </Icon>
          )
          break

        default:
          break
      }

      return (
        <NotificationContainer
          key={notification.sys.id}
          colour={notificationColour}
          textColour={notificationTextColour}
        >
          {notificationIcon}
          <NotificationContent>
            <NotificationContentText>
              {documentToReactComponents(notification.fields.content)}
            </NotificationContentText>
            {notification.fields.ctaButtons?.map((button) => {
              const internal = /^\/(?!\/)/.test(
                button?.fields?.url ? button.fields.url : '',
              )
              const key = `${button.fields.url}-${button.fields.label}` // Generate a unique key using the button's ID

              if (internal) {
                return (
                  <StyledLink key={key} to={button.fields.url}>
                    <Button
                      visualType={button.fields.type}
                      visualStyle={button.fields.style}
                    >
                      {button.fields.label}
                    </Button>
                  </StyledLink>
                )
              }

              return (
                <StyledALink key={key} href={button.fields.url}>
                  <Button
                    visualType={button.fields.type}
                    visualStyle={button.fields.style}
                  >
                    {button.fields.label}
                  </Button>
                </StyledALink>
              )
            })}
          </NotificationContent>
          <StyledClose
            style={{ fontSize: '3.4rem' }}
            onClick={(): void => handleClose(notification.sys.id)}
          />
        </NotificationContainer>
      )
    },
  )

  return (
    <CookiesProvider>
      <Notifications isMenuActive={props.isMenuActive}>
        {renderNotifications}
      </Notifications>
    </CookiesProvider>
  )
}

export default Notification
