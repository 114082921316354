import * as React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import theme from '../components/atoms/theme'
import Layout from '../components/layout'
import Header from '../components/molecules/header'
import GoogleMapsComponent from '../components/molecules/map'
import ContactForm from '../components/organisms/contact-form'
import ContactThankYouSection from '../components/organisms/contact-thank-you-section'

const BlueBlockSrc = require('../assets/blue-block.svg') as string

const AUCKLAND_LOCATION = { lat: -36.9153219, lng: 174.8157856 }
const WELLINGTON_LOCATION = { lat: -41.2824907, lng: 174.7741844 }
const CHRISTCHURCH_LOCATION = { lat: -43.5392118, lng: 172.5876614 }

const BodyContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 10.2rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    flex-direction: column-reverse;
  }
`

const InfoBlock = styled.div`
  background-image: url(${BlueBlockSrc});
  background-repeat: no-repeat;
  background-size: cover;
  height: 28.1rem;
  width: 34rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 4.5rem;
  color: ${theme.colours.white};
  text-align: center;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }
  div {
    margin-bottom: 1rem;
  }
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 4.2rem;
  h2 {
    margin-bottom: 0;
    margin-right: 3.2rem;
  }
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    margin-bottom: 2.7rem;
  }
`

const Line = styled.div`
  height: 1px;
  background-color: ${theme.colours.gray3};
  flex: 1;
`

const PhoneLink = styled.a`
  color: ${theme.colours.white};
  text-decoration: none;
  font-family: ${theme.fonts.primaryBold};
  font-size: 2.4rem;
`

const OfficesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    flex-direction: column;
  }
`

const ComplaintsContainer = styled.div`
  margin-bottom: 4.2rem;
`

const Office = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  &:not(:last-child) {
    margin-right: 3.1rem;
  }
  h3 {
    margin-bottom: 0.8rem;
  }
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    width: 100%;
    margin-right: 0 !important;
    margin-bottom: 3.2rem;
  }
`

const Address = styled.div`
  margin-bottom: 2.2rem;
`

const Instructions = styled.p`
  margin-top: 0;
  max-width: 88rem;
`

interface Props {
  location: {
    pathname: string
    state: {
      topic?: string
    }
  }
  showThankYou?: boolean
}

const Contact = (props: Props): JSX.Element => {
  return (
    <Layout currentPath={props.location.pathname}>
      <Helmet title='Get in touch' />
      <Header title='Get in touch' />
      <Instructions>
        We’re here to support you. Feedback, queries or community sponsorship
        can be sent to us via the form below or by phoning our nationwide
        freephone number. If you choose to contact us online, we’ll let you know
        that your enquiry has been received, and be in touch within 3 business
        days.
      </Instructions>
      <BodyContainer>
        {props.showThankYou ? (
          <ContactThankYouSection />
        ) : (
          <ContactForm location={props.location} />
        )}

        <InfoBlock>
          <div>Opening hours</div>
          <h3>
            Mon to Fri
            <br />
            8.30am - 5.00pm
          </h3>
          <div>Free phone nationwide</div>
          <h3>
            <PhoneLink href='tel:0800 287 75 26'>0800 287 75 26</PhoneLink>
          </h3>
        </InfoBlock>
      </BodyContainer>
      <TitleContainer>
        <h2>Complaints</h2>
        <Line />
      </TitleContainer>
      <ComplaintsContainer>
        To file a complaint email{' '}
        <a href={'mailto:complaints@pacificbusiness.co.nz'}>
          complaints@pacificbusiness.co.nz
        </a>
      </ComplaintsContainer>
      <TitleContainer>
        <h2>Our offices</h2>
        <Line />
      </TitleContainer>
      <OfficesContainer>
        <Office>
          <h3>Auckland</h3>
          <Address>Level 2, 45 O’Rorke Road, Penrose, Auckland 1061</Address>
          <GoogleMapsComponent center={AUCKLAND_LOCATION} />
        </Office>
        <Office>
          <h3>Wellington</h3>
          <Address>
            Level 13, NTT Tower, 157 Lambton Quay, Wellington Central,
            Wellington 6011
          </Address>
          <GoogleMapsComponent center={WELLINGTON_LOCATION} />
        </Office>
        <Office>
          <h3>Christchurch</h3>
          <Address>
            Unit 8/14 Acheron Drive, Riccarton, Christchurch 8041
          </Address>
          <GoogleMapsComponent center={CHRISTCHURCH_LOCATION} />
        </Office>
      </OfficesContainer>
    </Layout>
  )
}

export default Contact
