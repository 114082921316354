import * as React from 'react'
import styled from 'styled-components'
import theme from '../atoms/theme'

const tickSrc = require('../../assets/orange-circle-tick.svg') as string

const ThankYouContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding-bottom: 10rem;
  margin-bottom: 2.8rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    padding-top: 10rem;
    padding-bottom: 0;
    border: none;
  }
`

const Title = styled.h3`
  text-transform: uppercase;
  color: ${theme.colours.secondary};
  margin-top: 3rem;
`

const Tick = styled.img`
  width: 5rem;
`

const BodyText = styled.div`
  line-height: 2;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    text-align: center;
    margin-bottom: 2rem;
  }
`

const ContactThankYouSection = (): JSX.Element => {
  return (
    <ThankYouContainer>
      {/* biome-ignore lint/a11y/useAnchorContent: <explanation> */}
      <a href='#section' />
      <Tick src={tickSrc} alt='Tick' />
      <Title>Message sent successfully</Title>
      <BodyText>We have recevied your message.</BodyText>
      <BodyText>
        Someone from our staff will get in touch with you within 3 business
        days.
      </BodyText>
    </ThankYouContainer>
  )
}

export default ContactThankYouSection
