import { Link } from 'gatsby'
import * as React from 'react'
import styled from 'styled-components'
import type { ContentfulContentSetBlock } from '../../generated/graphql-types'
import { getIconSrc } from '../../helpers/getIconSrc'
import theme from '../atoms/theme'
import {
  ContentBlockTitle,
  ContentBlockWrapper,
  HeadingWrapper,
} from '../molecules/content-blocks'

interface ContentSetBlockProps {
  block: ContentfulContentSetBlock
}

const ContentSetBlocks = styled.div`
  gap: 2rem;
  display: flex;
  overflow-x: scroll;
  flex-wrap: nowrap;

  @media only screen and ${theme.breakpoints.fromMediumScreen} {
    justify-content: center;
    display: grid;
    overflow-x: auto;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  }
`

const ContentBlockTile = styled(Link)`
  padding: 2rem;
  border-radius: 2rem;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${theme.colours.gray4};
  width: 30rem;
  flex-shrink: 0;
  flex-grow: 0;
  
  &:hover {
    background-color: ${theme.colours.secondary};
    color: ${theme.colours.white}
    border: 1px solid ${theme.colours.secondary};
  }

  @media only screen and ${theme.breakpoints.fromMediumScreen} {
    padding: 5rem;
    width: auto;
  }
`

const ContentBlockTileTitle = styled.h3`
  font-family: ${theme.fonts.primary};
  color: ${theme.colours.offBlack};
  text-align: center;

  ${ContentBlockTile}:hover & {
    color: ${theme.colours.white};
  }
`

const ContentBlockTileDescription = styled.div`
  text-align: center;
  font-size: 1.6rem;
  color: ${theme.colours.darkGrey2};
  margin-bottom: 4rem;
  line-height: 140%;

  ${ContentBlockTile}:hover & {
    color: ${theme.colours.white};
  }
`

const IconWrapper = styled.div`
  width: 7.2rem;
  height: 7.2rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colours.gray5};
  margin-bottom: 2.4rem;
`

const ReadMore = styled.div`
  text-transform: uppercase;
  font-size: 1.6rem;
  color: ${theme.colours.secondary};
  font-family: ${theme.fonts.primarySemiBold};
  font-weight: 700;

  ${ContentBlockTile}:hover & {
    color: ${theme.colours.white};
  }
`

const Icon = styled.img`
  width: 3rem;
  height: 3rem;
`

const ContentSetBlock = (props: ContentSetBlockProps): JSX.Element => {
  const { block } = props
  const { title, contentBlocks } = block

  return (
    <ContentBlockWrapper>
      <HeadingWrapper>
        <ContentBlockTitle>{title}</ContentBlockTitle>
      </HeadingWrapper>
      <ContentSetBlocks>
        {contentBlocks?.map((contentBlock, index) => (
          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
          <ContentBlockTile to={contentBlock.buttonUrl} key={index}>
            <IconWrapper>
              <Icon src={getIconSrc(contentBlock.icon)} alt='Bar Graph Icon' />
            </IconWrapper>
            <ContentBlockTileTitle>{contentBlock.title}</ContentBlockTileTitle>
            <ContentBlockTileDescription>
              {contentBlock.description.description}
            </ContentBlockTileDescription>
            <ReadMore>{contentBlock.buttonLabel}</ReadMore>
          </ContentBlockTile>
        ))}
      </ContentSetBlocks>
    </ContentBlockWrapper>
  )
}

export default ContentSetBlock
