import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import * as React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import ArrowRightIcon from '../../components/atoms/graphics/arrow-right'
import theme from '../../components/atoms/theme'
import Layout from '../../components/layout'
import Breadcrumbs from '../../components/molecules/breadcrumbs'
import Button from '../../components/molecules/button'
import Header from '../../components/molecules/header'

const Description = styled.h3`
  text-align: left;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    text-align: left;
    max-width: 100%;
    margin-bottom: 2.4rem;
    font-size: 1.9rem;
  }
`

const BodyContainer = styled.div`
  display: flex;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    flex-direction: column;
  }
`

const ContentContainer = styled.div`
  width: 50%;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    width: 100%;
    order: 2;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const StyledButton = styled(Button)`
  padding: 1rem 2.4rem;
  margin-top: 2.5rem;
  height: 4.4rem;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  min-width: unset;
  span {
    padding-right: 0.5rem;
  }
`

const ImageContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    width: 100%;
    order: 1;
    margin-bottom: 2.4rem;
  }
`

const Image = styled.img`
  width: 90%;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    width: 100%;
  }
`

interface Props {
  location: {
    pathname: string
  }
}

const HatchPacificServices = (props: Props): JSX.Element => {
  const siteTitle = get(props, 'data.site.siteMetadata.title')
  const currentPath = get(props, 'location.pathname')
  const allPages = get(props, 'data.allContentfulPage.nodes')
  const currentPage = allPages.find((page) => get(page, 'slug') === currentPath)
  const description = currentPage ? currentPage.description.description : null
  //   TODO: This should use a reusable content type
  const hatchPacificServicesServices = get(
    props,
    'data.allContentfulRichTextOnLeftImageOnRightBlock.nodes[0]',
  )

  return (
    <Layout currentPath={currentPath}>
      <Helmet title={siteTitle} />
      <Header title={currentPage.title}>
        <Breadcrumbs
          crumbs={[
            {
              label: 'Services',
              path: '/services/',
            },
            {
              label: currentPage.title,
              isActive: true,
            },
          ]}
        />
      </Header>
      {hatchPacificServicesServices && (
        <BodyContainer>
          <ContentContainer>
            {description && <Description>{description}</Description>}
            {hatchPacificServicesServices.body && (
              <div>
                {documentToReactComponents(
                  hatchPacificServicesServices.body.json,
                )}
              </div>
            )}
            {hatchPacificServicesServices.buttonLabel && (
              <StyledLink
                to={
                  hatchPacificServicesServices.buttonUrl
                    ? hatchPacificServicesServices.buttonUrl
                    : '#'
                }
              >
                <StyledButton>
                  {' '}
                  <span>{hatchPacificServicesServices.buttonLabel} </span>{' '}
                  <ArrowRightIcon />{' '}
                </StyledButton>
              </StyledLink>
            )}
          </ContentContainer>
          <ImageContainer>
            <Image
              src={hatchPacificServicesServices.image.file.url}
              alt={hatchPacificServicesServices.image.title}
            />
          </ImageContainer>
        </BodyContainer>
      )}
    </Layout>
  )
}

export default HatchPacificServices

export const pageQuery = graphql`
  query HatchPacificServicesQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulPage {
      nodes {
        title
        slug
        description {
          description
        }
      }
    }
    allContentfulRichTextOnLeftImageOnRightBlock(
      filter: { contentful_id: { eq: "4EG5h2jeParYcT7OoSbFND" } }
    ) {
      nodes {
        buttonLabel
        buttonUrl
        body {
          json
        }
        image {
          title
          file {
            url
          }
        }
      }
    }
  }
`
