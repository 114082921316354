import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Document } from '@contentful/rich-text-types'
import * as React from 'react'
import styled from 'styled-components'
import theme from '../atoms/theme'
import Slider from '../molecules/carousel'

const largeSquiggle = require('../../assets/large-squiggle.svg') as string

const TestimonialsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    margin-bottom: 2.4rem;
  }
`

const Title = styled.h1`
  color: ${theme.colours.secondary};
  max-width: 48.5rem;
  margin-bottom: 1.6rem;
  margin-right: 17rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    font-size: 2.4rem;
    font-family: ${theme.fonts.primaryBold};
  }
`

const Squiggle = styled.img`
  width: 13.8rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    width: 8rem;
  }
`

const TestimonialPerson = styled.h6`
  text-transform: uppercase;
  color: ${theme.colours.quaternary};
`

const TestimonialContainer = styled.div`
  p {
    margin-top: 0;
  }
`

interface TestimonialProps {
  text: {
    json: Document
  }
  personName: string
  personRole: string
}

const Testimonial = (props: TestimonialProps): JSX.Element => {
  return (
    <TestimonialContainer>
      {documentToReactComponents(props.text.json)}
      <TestimonialPerson>
        {props.personRole && `${props.personRole},`} {props.personName}
      </TestimonialPerson>
    </TestimonialContainer>
  )
}

interface Props {
  testimonials: Array<TestimonialProps>
}

const Testimonials = (props: Props): JSX.Element => {
  return (
    <>
      <TestimonialsContainer>
        <TitleContainer>
          <Title>Community success stories</Title>
          <Squiggle src={largeSquiggle} alt='Squiggle' />
        </TitleContainer>
        {props?.testimonials && props?.testimonials.length > 0 && (
          <Slider
            carouselItems={props?.testimonials?.map(
              (testimonial: TestimonialProps, index: number) => (
                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                <Testimonial key={index} {...testimonial} />
              ),
            )}
          />
        )}
      </TestimonialsContainer>
    </>
  )
}

export default Testimonials
