import React, { ReactNode } from 'react'
import styled from 'styled-components'
import theme from '../atoms/theme'

const headerStripImage = require('../../assets/header-strip.svg') as string
const largeSquiggle = require('../../assets/large-squiggle.svg') as string

interface HeaderProps {
  hasChildren?: boolean
}
const HeaderContainer = styled.div`
  margin-bottom: 4rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    margin-bottom: 0;
  }
`

const HeaderStripContainer = styled.div`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
`

const HeaderStrip = styled.div`
  background-image: url(${headerStripImage});
  background-size: cover;
  background-repeat: no-repeat;
  height: 7rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    height: 4rem;
  }
`

const Title = styled.h1`
  max-width: 80rem;
  color: ${theme.colours.secondary};
  margin-bottom: 0;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    margin-bottom: 0.8rem;
    font-size: 2.8rem;
    line-height: 4rem;
  }
`

const HeaderBody = styled.div`
  padding: ${(props: HeaderProps): string =>
    props.hasChildren ? '2.5rem 0' : '4rem 0'};
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    padding-top: 1.6rem;
  }
`

const Squiggle = styled.img`
  width: 13.8rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    width: 8rem;
  }
`

interface Props {
  title: string
  children?: ReactNode
}

const Header = (props: Props): JSX.Element => {
  const hasChildren = !!props.children
  return (
    <HeaderContainer>
      <HeaderStripContainer>
        <HeaderStrip />
      </HeaderStripContainer>
      {props.children}
      <HeaderBody hasChildren={hasChildren}>
        <Title>{props.title}</Title>
        <Squiggle src={largeSquiggle} alt='Squiggle' />
      </HeaderBody>
    </HeaderContainer>
  )
}

export default Header
