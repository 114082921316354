import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import type {
  ContentfulRichTextOnLeftImageOnRightBlock,
  ContentfulRichTextOnLeftImageOnRightOldBlock,
} from '../../generated/graphql-types'
import ArrowRightIcon from '../atoms/graphics/arrow-right'
import theme from '../atoms/theme'
import Button from '../molecules/button'

const Description = styled.h3`
  max-width: 100%;
  text-align: left;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    text-align: left;
    max-width: 100%;
    margin-bottom: 3.7rem;
    font-size: 1.9rem;
  }
`

const BodyContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const LeftContainer = styled.div`
  width: 48%;
  padding-right: 2.5rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    width: 100%;
    padding-right: 0;
    order: 2;
  }
`

const ImageContainer = styled.div`
  width: 48%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    width: 100%;
    order: 1;
    height: 23rem;
    margin-bottom: 2.5rem;
  }
`

const Line = styled.div`
  height: 1px;
  background-color: ${theme.colours.gray3};
  width: 100%;
  margin: 7rem 0;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    margin: 2.5rem 0;
    order: 3;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const StyledButton = styled(Button)`
  padding: 10px 24px;
  height: 4.4rem;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  min-width: unset;
  span {
    padding-right: 0.5rem;
  }
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    justify-content: center;
  }
`

const StyledImage = styled.img`
  object-fit: contain;
`

const ContentContainer = styled.div`
  ul {
    p {
      margin: 0;
    }
  }
`

export const RichTextOnLeftImageOnRightBlock = ({
  block,
}: {
  block:
    | ContentfulRichTextOnLeftImageOnRightBlock
    | ContentfulRichTextOnLeftImageOnRightOldBlock
}) => {
  const { body, buttonLabel, buttonUrl, image } = block
  return (
    <BodyContainer>
      <LeftContainer>
        {body && (
          <ContentContainer>
            {documentToReactComponents(body.json)}
          </ContentContainer>
        )}
        {buttonLabel && (
          <StyledLink to={buttonUrl ? buttonUrl : '#'}>
            <StyledButton>
              <span>{buttonLabel}</span> <ArrowRightIcon />
            </StyledButton>
          </StyledLink>
        )}
      </LeftContainer>
      <ImageContainer>
        {image?.file?.url ? (
          <StyledImage src={image?.file?.url} alt={image?.title || ''} />
        ) : (
          'Digital platform screenshot'
        )}
      </ImageContainer>
      <Line />
    </BodyContainer>
  )
}
