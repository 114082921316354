import { graphql, useStaticQuery } from 'gatsby'
import { FluidObject } from 'gatsby-image'

interface ContentfulAssetNode {
  file: {
    url: string
  }
  fluid: FluidObject
}

interface ContentfulImageQueryResult {
  allContentfulAsset: {
    nodes: ContentfulAssetNode[]
  }
}

const useContentfulImage = (assetUrl: string): FluidObject | null => {
  const { allContentfulAsset } = useStaticQuery<ContentfulImageQueryResult>(
    graphql`
      query CONTENTFUL_IMAGE_QUERY {
        allContentfulAsset {
          nodes {
            ...GatsbyFluidImageFields
          }
        }
      }
    `,
  )

  // Find the asset by its URL
  const asset = allContentfulAsset.nodes.find(
    (node) => node.file.url === assetUrl,
  )

  if (!asset?.fluid) {
    console.error(
      `Asset not found or fluid data is missing for URL: ${assetUrl}`,
    )
    return null
  }

  return asset.fluid
}

export default useContentfulImage
