import { navigate } from 'gatsby'
import get from 'lodash/get'
import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import Spinner from '../components/atoms/spinner'
import theme from '../components/atoms/theme'
import { LAMBDA_URL } from '../components/constants'
import Layout from '../components/layout'
import Breadcrumbs from '../components/molecules/breadcrumbs'
import Header from '../components/molecules/header'
import EventDetail from '../components/organisms/events/event-detail'

interface Props {
  location: {
    pathname: string
  }
}

const MobileImage = styled.img`
  display: none;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    display: block;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }
`

const SpinnerContainer = styled.div`
  padding: 12rem;
  padding-top: 14rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    padding: 10rem;
  }
`

const spinnerStyle = {
  height: '20rem',
}

const EventDetails = (props: Props): JSX.Element => {
  let eventType = ''
  let eventId = ''
  let isWorkshop: boolean | undefined
  let query: string
  const currentPath = get(props, 'path')
  const pathName = props.location.pathname
  const paths = pathName.split('/')
  const isValidPath = paths.length >= 4 && paths[3].length > 0
  const [eventData, setEventData] = useState(null)
  const [isLoading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)

  if (isValidPath) {
    eventType = paths[2]
    eventId = paths[3]
    isWorkshop = eventType.includes('workshops')
    query = `
      query {
        events(
          filter: {
            id: "${eventId}"
          }
        ) {
          id
          title
          eventType
          startDate
          shortDescription
          description
          endDate
          locationName
          locationStreet
          locationCity
          locationPostCode
          locationCountry
          registrationUrl
          imageUrl
          seatingStatus
          detailedEventType
        }
      }
    `
  }

  const fetchData = async (retryCount = 0): Promise<void> => {
    try {
      const response = await fetch(LAMBDA_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ query }),
      })

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const resultData = await response.json()
      setEventData(resultData.data.events[0])
      setLoading(false)
    } catch (error) {
      console.error('Error fetching event data:', error)

      if (retryCount < 3) {
        setTimeout(() => fetchData(retryCount + 1), 1000)
      } else {
        setHasError(true)
        setLoading(false)
      }
    }
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!isValidPath) {
      navigate('/404/')
    } else {
      fetchData()
    }
  }, [])

  if (hasError) {
    return (
      <div>There was an error loading the event. Please try again later.</div>
    )
  }

  return (
    <Layout currentPath={`${currentPath}/${eventType}/${eventId}`}>
      {isLoading ? (
        <SpinnerContainer>
          <Spinner style={spinnerStyle} alternative={true} />
        </SpinnerContainer>
      ) : eventData ? (
        <React.Fragment>
          <Helmet title={eventData.title} />
          <Header title={eventData.title}>
            <MobileImage src={eventData.imageUrl} />
            <Breadcrumbs
              crumbs={[
                {
                  label: isWorkshop ? 'Workshops' : 'Events',
                  path: isWorkshop ? '/services/workshops/' : '/events/',
                },
                {
                  label: eventData.title,
                  isActive: true,
                },
              ]}
            />
          </Header>
          <EventDetail
            data={eventData}
            path={process.env.GATSBY_WEBSITE_URL + props.location.pathname}
          />
        </React.Fragment>
      ) : null}
    </Layout>
  )
}

export default EventDetails
