import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import type { ContentfulFlexibleTextAndImageBlock } from '../../generated/graphql-types'
import ArrowRightIcon from '../atoms/graphics/arrow-right'
import theme from '../atoms/theme'
import Button from '../molecules/button'

const ServiceContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0;
  max-width: 100%;
  align-items: center;
  justify-content: space-between;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    max-width: 100%;
    flex: 1;
    flex-direction: column;
    margin-bottom: 2.8rem;
  }
`

interface StyleProps {
  order?: 'left' | 'right'
}

const ServiceImgContainer = styled.div`
  width: 48%;
  order: ${(props: StyleProps) => (props.order === 'left' ? 1 : 2)};
  padding: 20px;
  height: 40rem;
  display: flex;
  align-items: center;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    width: 100%;
    order: 1;
    height: auto;
    padding: 2rem 0;
  }
`

const ServiceImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const ServiceContent = styled.div`
  width: 48%;
  order: 1;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    width: 100%;
    order: 2;
  }
`

const ServiceDescription = styled.div`
  padding-top: 2rem;
  padding-bottom: 3rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    padding-top: 1.5rem;
    padding-bottom: 2.5rem;
  }
`

const ServiceTitle = styled.h2`
  margin-bottom: 0.8rem;
  color: ${theme.colours.secondary};
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    font-size: 2.1rem;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const StyledButton = styled(Button)`
  padding: 10px 24px;
  height: 4.4rem;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  min-width: unset;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    justify-content: center;
  }
`

export const FlexibleTextAndImageBlock = ({
  block,
}: { block: ContentfulFlexibleTextAndImageBlock }): JSX.Element => {
  const {
    title,
    image,
    imagePlacement,
    buttonLabel,
    buttonUrl,
    descriptionNew,
  } = block

  return (
    <ServiceContainer>
      {image && (
        <ServiceImgContainer
          order={
            imagePlacement === 'left' || imagePlacement === 'right'
              ? imagePlacement
              : 'left'
          }
        >
          {image?.file?.url && (
            <ServiceImg src={image.file.url} alt={title || ''} />
          )}
        </ServiceImgContainer>
      )}
      <ServiceContent>
        {title && <ServiceTitle>{title}</ServiceTitle>}
        {descriptionNew?.descriptionNew && (
          <ServiceDescription>
            {descriptionNew?.descriptionNew}
          </ServiceDescription>
        )}
        {buttonLabel && buttonUrl && (
          <StyledLink to={buttonUrl}>
            <StyledButton>
              {buttonLabel}
              <ArrowRightIcon colour={theme.colours.white} />
            </StyledButton>
          </StyledLink>
        )}
      </ServiceContent>
    </ServiceContainer>
  )
}
