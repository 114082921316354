import * as React from 'react'
import theme from '../theme'

interface Props {
  colour?: string
}

const FileIcon = (props: Props): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='21'
      viewBox='0 0 24 21'
    >
      <title>File</title>
      <path
        fill={props.colour ? props.colour : theme.colours.black}
        fillRule='evenodd'
        d='M20.9 7.525c-.1-.088-.1-.175-.2-.263l-7-6.124c-.1-.088-.2-.175-.3-.175-.1-.088-.3-.088-.4-.088H6C4.3.875 3 2.013 3 3.5v14c0 1.488 1.3 2.625 3 2.625h12c1.7 0 3-1.137 3-2.625V7.875c0-.088 0-.263-.1-.35zM14 3.85L17.6 7H14V3.85zm4 14.525H6c-.6 0-1-.35-1-.875v-14c0-.525.4-.875 1-.875h6v5.25c0 .525.4.875 1 .875h6v8.75c0 .525-.4.875-1 .875z'
      />
    </svg>
  )
}

export default FileIcon
