import React, { ChangeEvent, useState } from 'react'
import isUrl from 'validator/lib/isURL'
import Input from '../../atoms/input'
import RadioGroup from '../../atoms/radio-group'
import Row from '../../atoms/row'
import Select from '../../atoms/select'
import Separator from '../../atoms/separator'
import TextArea from '../../atoms/text-area'
import FormSection from './form-section'

interface Props {
  handleCompanyNameChange: (event: ChangeEvent<HTMLInputElement>) => void
  handleSocialEnterpriseChange: (event: ChangeEvent<HTMLInputElement>) => void
  handleWebsiteChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const BusinessDetailsSection = (props: Props): JSX.Element => {
  const [errors, setErrors] = useState({
    socialEnterprise: '',
    companyName: '',
    website: '',
  })

  const validateWebsite = (website: string): void => {
    if (website) {
      if (!isUrl(website)) {
        setErrors(
          Object.assign({}, errors, { website: 'Invalid website format' }),
        )
      } else {
        setErrors(Object.assign({}, errors, { website: '' }))
      }
    }
  }

  const validateRequiredFieldOnBlur = (value, errorKey): void => {
    if (!value) {
      setErrors(
        Object.assign({}, errors, { [errorKey]: 'This field is required' }),
      )
    } else {
      setErrors(Object.assign({}, errors, { [errorKey]: '' }))
    }
  }

  return (
    <FormSection>
      <div>
        <Row>
          <RadioGroup
            name='social-enterprise'
            label='Is your company a Social Enterprise?*'
            onChange={props.handleSocialEnterpriseChange}
            options={['yes', 'no']}
            errorMessage={errors.socialEnterprise}
            onBlur={(isChecked: boolean): void =>
              validateRequiredFieldOnBlur(isChecked, 'socialEnterprise')
            }
          />
        </Row>
        <Row>
          <Input
            label='Company Name*'
            onChange={props.handleCompanyNameChange}
            placeholder='Trading name'
            errorMessage={errors.companyName}
            onBlur={(event: ChangeEvent<HTMLInputElement>): void =>
              validateRequiredFieldOnBlur(event.target.value, 'companyName')
            }
          />
          <Separator />
          <Input
            label='Website'
            name='url'
            placeholder='www...'
            onChange={props.handleWebsiteChange}
            errorMessage={errors.website}
            onBlur={(event: ChangeEvent<HTMLInputElement>): void =>
              validateWebsite(event.target.value)
            }
          />
        </Row>
        <Row>
          <Select
            label='Industry'
            name='industry'
            placeholder='Industry you work in'
            options={[
              'Accommodation and Food Services (Excluding Tourism Businesses)',
              'Administrative and Support Services',
              'Agriculture, Forestry and Fishing',
              'Arts and Recreation Services',
              'Construction',
              'Education and Training',
              'Electricity, Gas, Water and Waste Services',
              'Financial and Insurance Services',
              'Health Care and Social Assistance',
              'Information, Media and Telecommunications',
              'Manufacturing',
              'Mining',
              'Other Services',
              'Professional Scientific and Technical Services',
              'Public Administration and Safety',
              'Rental, Hiring and Real Estate Services',
              'Retail Trade',
              'Tourism',
              'Transport, Postal and Warehousing',
              'Wholesale Trade',
            ]}
          />
          <Separator />
          <Select
            label='Region'
            name='00N2800000BMc1N'
            placeholder='Where the company operates'
            options={[
              'Auckland',
              'Bay of Plenty',
              'Canterbury',
              'Gisborne',
              "Hawke's Bay",
              'Manawatu-Wanganui',
              'Marlborough',
              'Nelson-Tasman',
              'Northland',
              'Otago',
              'Southland',
              'Taranaki',
              'Waikato',
              'Wellington',
              'West Coast',
            ]}
          />
        </Row>

        <Row>
          <TextArea
            label='Tell us about you and your business and where you would like help/support'
            name='description'
          />
        </Row>
      </div>
    </FormSection>
  )
}

export default BusinessDetailsSection
