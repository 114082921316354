import { Link } from 'gatsby'
import * as React from 'react'
import styled from 'styled-components'
import theme from '../atoms/theme'
import Button from '../molecules/button'

interface NavigationCTAProps {
  title: string
  to: string
  active?: boolean
}

interface StyleProps {
  active?: string
}

const StyledLink = styled(Link)`
  position: relative;
  color: ${(props: StyleProps): string =>
    props.active === 'true' ? theme.colours.quaternary : theme.colours.black};
  line-height: 2.4rem;
  letter-spacing: 0.08rem;
  font-family: ${theme.fonts.primaryBold};
  text-decoration: none;
  &:hover {
    svg {
      fill: ${(props: StyleProps): string =>
        props.active === 'true'
          ? theme.colours.quaternary
          : theme.colours.black};
      transform: translateX(0);
      transition: -webkit-transform 0.2s ease;
    }
    .squiggle-wrapper {
      width: 7.4rem;
      transition: width 0.2s;
    }
  }
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    width: 100%;
    padding: 0 1.5rem 1.5rem;
    margin-bottom: 4rem;
  }
`

const NavigationCTAContainer = styled.div`
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    margin-left: 0;
  }
`
const JoinButton = styled(Button)`
  font-size: 1.4rem;
  padding: 1rem 1.5rem;
  height: unset;
  margin-left: 0.5rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    margin: 0;
  }
`
const NavigationCTA = (props: NavigationCTAProps): JSX.Element => {
  return (
    <StyledLink active={props.active.toString()} to={props.to}>
      <JoinButton>
        <NavigationCTAContainer data-test-id='navitem-cta' {...props}>
          {props.title}
        </NavigationCTAContainer>
      </JoinButton>
    </StyledLink>
  )
}

export default NavigationCTA
