import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import React, { useState } from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import theme from '../components/atoms/theme'
import Layout from '../components/layout'
import Header from '../components/molecules/header'
import ResourcesSubcategory from '../components/organisms/resources-subcategory'

interface Props {
  test: string
}

interface StyleProps {
  active?: boolean
}

const Description = styled.h3`
  max-width: 66%;
  text-align: left;
  @media only screen and ${theme.breakpoints.toMediumScreen} {
    font-size: 1.9rem;
    text-align: left;
    max-width: 100%;
    margin-bottom: 3.7rem;
  }
`
const CategoryTab = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border-top: 1px solid ${theme.colours.gray1};
  border-bottom: 1px solid ${theme.colours.gray1};
`

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ContentContainer = styled.div`
  display: flex;
  margin-top: 4.5rem;
`

export const Tab = styled.div`
  font-size: 1.6rem;
  text-align: center;
  width: 19rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5rem 1.5rem;
  border: none;
  border-bottom: ${(props: StyleProps): string =>
    props.active ? `2px solid ${theme.colours.quaternary}` : ''};
  outline: none;
  cursor: pointer;
  :hover {
    border-bottom: 2px solid ${theme.colours.quaternary};
  }
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    font-size: 1rem;
    padding: 1.5rem 0.5rem;
    flex-grow: 1;
  }
`

const ContentHeader = styled.h2`
  color: ${theme.colours.secondary};
  margin-bottom: 1.5rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    font-size: 2.1rem;
  }
`

const CategoryDescription = styled.div`
  width: 50%;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    width: 100%;
  }
`

export const Content = styled.div`
  display: ${(props: StyleProps): string => (props.active ? 'block' : 'none')};
  width: 100%;
`

const Resources = (props: Props): JSX.Element => {
  const siteTitle = get(props, 'data.site.siteMetadata.title')
  const currentPath = get(props, 'location.pathname')
  const allPages = get(props, 'data.allContentfulPage.nodes')
  const currentPage = allPages.find((page) => get(page, 'slug') === currentPath)
  const description = currentPage ? currentPage.description.description : null
  const allCategory = get(props, 'data.allContentfulResourcesCategory.nodes')

  const [active, setActiveTab] = useState(0)
  const handleClick = (e): void => {
    const tab = Number.parseInt(e.target.id, 0)
    if (tab !== active) {
      setActiveTab(tab)
    }
  }

  return (
    <Layout currentPath={currentPath}>
      <Helmet title={siteTitle} />
      <Header title={currentPage.title} />
      {description && <Description>{description}</Description>}
      <BodyContainer>
        <CategoryTab>
          {allCategory.map((category, index) => {
            return (
              <Tab
                onClick={handleClick}
                active={active === index}
                id={index}
                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                key={index}
              >
                {category.category}
              </Tab>
            )
          })}
        </CategoryTab>
        <ContentContainer>
          {allCategory.map((category, index) => {
            return (
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              <Content active={active === index} key={index}>
                <ContentHeader>{category.header}</ContentHeader>
                {category.description && (
                  <CategoryDescription>
                    {documentToReactComponents(category.description.json)}
                  </CategoryDescription>
                )}
                <ResourcesSubcategory subcategory={category.subcategory} />
              </Content>
            )
          })}
        </ContentContainer>
      </BodyContainer>
    </Layout>
  )
}

export default Resources

export const pageQuery = graphql`
  query ResourcesQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulPage {
      nodes {
        title
        slug
        description {
          description
        }
      }
    }
    allContentfulResourcesCategory(sort: { fields: [position], order: ASC }) {
      nodes {
        category
        position
        header
        description {
          json
        }
        subcategory {
          header
          resourceLinks {
            title
            resourceType
            resourceUrl
            resourceDocument {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`
