import { Link } from 'gatsby'
import * as React from 'react'
import styled from 'styled-components'
import theme from '../atoms/theme'

interface BreadCrumb {
  label: string
  path?: string
  isActive?: boolean
}

interface Props {
  crumbs: Array<BreadCrumb>
}

const BreadcrumbsContainer = styled.div`
  height: 100%;
  margin: 0 auto;
  max-width: 144rem;
  padding: 0 2rem;
  display: flex;
  align-items: center;
`

const Crumb = styled.span`
  font-size: 1.6rem;
  color: ${theme.colours.white};
  font-family: ${theme.fonts.primarySemiBold};
  margin-right: 0.4rem;
  border-bottom: ${(props: BreadCrumb): string =>
    props.isActive ? '' : `0.1rem solid ${theme.colours.white}`};
  padding-bottom: 0.2rem;
`

const ActiveCrumb = styled(Crumb)`
  @media only screen and ${theme.breakpoints.toMediumScreen} {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 20rem;
    line-height: 1;
    display: inline-block;
    padding: 0;
    vertical-align: middle;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const CrumbContainer = styled.div`
  margin-right: 0.4rem;
`

const Arrow = styled.span`
  font-size: 1.2rem;
  color: ${theme.colours.white};
`

const Breadcrumbs = (props: Props): JSX.Element => {
  return (
    <BreadcrumbsContainer>
      {props.crumbs.map((crumb: BreadCrumb, index: number) => {
        return (
          <CrumbContainer key={crumb.label}>
            {crumb.path ? (
              <StyledLink to={crumb.path}>
                <Crumb {...crumb}>{crumb.label}</Crumb>
              </StyledLink>
            ) : (
              <ActiveCrumb {...crumb}>{crumb.label}</ActiveCrumb>
            )}
            {index < props.crumbs.length - 1 && <Arrow>&gt;</Arrow>}
          </CrumbContainer>
        )
      })}
    </BreadcrumbsContainer>
  )
}

export default Breadcrumbs
