import { Link } from 'gatsby'
import React, { useState } from 'react'
import styled from 'styled-components'
import CommentIcon from '../atoms/graphics/comment'
import DangerIcon from '../atoms/graphics/danger-icon'
import FacebookIcon from '../atoms/graphics/facebook-icon'
import InstagramIcon from '../atoms/graphics/instagram-icon'
import LetterIcon from '../atoms/graphics/letter-icon'
import LinkedinIcon from '../atoms/graphics/linkedin-icon'
import PhoneIcon from '../atoms/graphics/phone-icon'
import QuestionMark from '../atoms/graphics/question-mark'
import TwitterIcon from '../atoms/graphics/twitter-icon'
import YoutubeIcon from '../atoms/graphics/youtube-icon'
import theme from '../atoms/theme'
import NewsletterModal from '../organisms/newsletter-form/newsletter'
import Button from './button'
import ModalComponent from './modal'

const arrowSrc = require('../../assets/arrow.svg') as string

const FooterContainer = styled.footer`
  width: 100%;
  background-color: ${theme.colours.darkGrey};
  padding: 8rem 21rem 0 21rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    padding: 4rem 0;
  }
`

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 2rem;
  max-width: 144rem;
  width: 100%;
  margin: 0 auto;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    flex-direction: column;
    padding: 0 1.6rem;
    align-items: center;
  }
`

const Arrow = styled.img`
  width: 1.6rem;
  margin-right: 1.2rem;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  color: ${theme.colours.white};
  margin-bottom: 8rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    align-items: center;
    margin-bottom: 4.2rem;
  }
`

const LastColumn = styled(Column)`
  flex: 0 0 50%;
  & button {
    width: 100%;
  }
`

const SocialColumn = styled(Column)`
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    flex-direction: row;
    & .icon-label {
      display: none;
    }
    & > div:not(.newsletter) {
      display: none;
    }
    & svg {
      margin: 0 1.5rem;
    }
  }
`

const ColumnTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${theme.colours.white};
  margin-bottom: 3rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    margin-left: auto;
    margin-right: auto;
  }
`

const ColumnTitle = styled.h6`
  margin: 0;
  font-size: 1.6rem;
  text-transform: uppercase;
`

const IconText = styled.div`
  margin-bottom: 1.2rem;
  display: flex;
  align-items: center;
  & svg {
    min-width: 1.6rem;
    margin-right: 1.6rem;
  }
  & div {
    color: ${theme.colours.white};
  }
  cursor: pointer;

  &:hover {
    & > div {
      color: ${theme.colours.quaternary};
      text-decoration: underline;
    }
  }
`

const Seperator = styled.div`
  height: 4rem;
`

const Line = styled.div`
  height: 0.1rem;
  width: 100%;
  background-color: ${theme.colours.quaternary};
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    display: none;
  }
`

const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 144rem;
  width: 100%;
  margin: 0 auto;
  padding: 3rem 10rem;
  color: ${theme.colours.white};
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    flex-direction: column-reverse;
    align-items: center;
    padding: 0 1.6rem;
  }
`

const Divider = styled.div`
  background-color: ${theme.colours.white};
  width: 0.1rem;
  height: 1.6rem;
  margin: 0 1.2rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    display: none;
  }
`

const Policies = styled.div`
  display: flex;
  align-items: center;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    flex-direction: column;
    margin-bottom: 3rem;
  }
`

const PolicyLink = styled(Link)`
  font-size: 1.4rem;
  color: ${theme.colours.white};
  text-decoration: none;
  & :hover {
    color: ${theme.colours.quaternary};
  }
`

const Text = styled.div`
  color: ${theme.colours.white};
  margin-bottom: 3rem;
  font-size: 1.6rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    text-align: center;
    font-size: 1.4rem;
  }
`

const CTA = styled(Link)`
  max-width: 35.5rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    width: 100%;
  }
`

interface ColumnTitleProps {
  title: string
}

interface RowProps {
  icon: (IconProps) => JSX.Element
  text: string
  link?: string
  external?: boolean
  state?: object
  onClick?: () => void
  class?: string
}

const ColumnTitleComponent = (props: ColumnTitleProps): JSX.Element => {
  return (
    <ColumnTitleContainer>
      <Arrow src={arrowSrc} alt='Arrow' />
      <ColumnTitle>{props.title}</ColumnTitle>
    </ColumnTitleContainer>
  )
}

const LinkText = styled.div`
  font-size: 1.4rem;
`

const RegisterLink = styled(Link)`
  text-decoration: none;
  color: ${theme.colours.white};
  font-family: ${theme.fonts.primarySemiBold};
  font-size: 1.6rem;
  line-height: 1.1;
  letter-spacing: 0.058rem;
  text-transform: uppercase;
  padding-top: 4rem;
  &:hover {
    color: ${theme.colours.quaternaryHover};
  }
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    text-align: center;
    font-size: 1.4rem;
    padding-top: 1.5rem;
  }
`

const StyledAnchor = styled.a`
  text-decoration: none;
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const Row = (props: RowProps): JSX.Element => {
  const Icon = props.icon
  const [isHovered, setIsHovered] = useState(false)

  const toggleHover = (): void => {
    setIsHovered(!isHovered)
  }

  const LinkBody = (
    <IconText
      className={props.class}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
      onClick={props.onClick}
    >
      <Icon active={isHovered} />
      <LinkText className='icon-label'>{props.text}</LinkText>
    </IconText>
  )

  return props.external ? (
    <StyledAnchor href={props.link} target='_blank'>
      {LinkBody}
    </StyledAnchor>
  ) : props.link ? (
    <StyledLink state={props.state} to={props.link}>
      {LinkBody}
    </StyledLink>
  ) : (
    LinkBody
  )
}

const Footer = (): JSX.Element => {
  const [modalIsOpen, setIsOpen] = React.useState(false)

  const openModal = (): void => {
    setIsOpen(true)
  }

  const closeModal = (): void => {
    setIsOpen(false)
  }

  const enableRegistration = false

  return (
    <FooterContainer>
      <TopSection>
        <Column>
          <ColumnTitleComponent title='Get in touch' />
          <Row
            state={{ topic: 'Query' }}
            link='/contact/'
            text='Have a question?'
            icon={QuestionMark}
          />
          <Row
            state={{ topic: 'Feedback' }}
            link='/contact/'
            text='Have a comment?'
            icon={CommentIcon}
          />
          <Row
            state={{ topic: 'Complaint' }}
            link='/contact/'
            text='Something wrong?'
            icon={DangerIcon}
          />
          <Seperator />
          <Row
            external
            link='tel:0800 287 7526'
            text='0800 287 7526'
            icon={PhoneIcon}
          />
        </Column>
        <SocialColumn>
          <ColumnTitleComponent title='Find us' />
          <Row
            external
            link='https://www.facebook.com/pacificbusinesstrust/'
            text='Facebook'
            icon={FacebookIcon}
          />
          <Row
            external
            link='https://www.instagram.com/pacificbusinesstrust/'
            text='Instagram'
            icon={InstagramIcon}
          />
          <Row
            external
            link='https://twitter.com/pbtrustnz'
            text='Twitter'
            icon={TwitterIcon}
          />
          <Row
            external
            link='https://www.youtube.com/channel/UCdrczEa8-lr19EN-wOOJRkw'
            text='YouTube'
            icon={YoutubeIcon}
          />
          <Row
            external
            link='https://www.linkedin.com/company/pacific-business-trust/'
            text='LinkedIn'
            icon={LinkedinIcon}
          />
          <Row
            class='newsletter'
            text='Sign up for Newsletter'
            icon={LetterIcon}
            onClick={openModal}
          />
          <ModalComponent
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel='Newsletter'
          >
            <NewsletterModal onClose={closeModal} />
          </ModalComponent>
        </SocialColumn>
        <LastColumn>
          <ColumnTitleComponent title='Join the community' />
          <Text>
            Learn from the experts, grow your business and help other Pacific
            business owners.
          </Text>
          <CTA to='/join-community/#community-form'>
            <Button alternative>Join our community</Button>
          </CTA>
          {enableRegistration && (
            <RegisterLink to='/service-provider-registration/'>
              Register to become a Service Provider
            </RegisterLink>
          )}
        </LastColumn>
      </TopSection>
      <Line />
      <BottomSection>
        <LinkText>Ⓒ All Rights Reserved 2020</LinkText>
        <Policies>
          <PolicyLink to='/terms-of-use'>Terms of use</PolicyLink>
          <Divider />
          <PolicyLink to='/privacy-policy'>Privacy Policy</PolicyLink>
        </Policies>
      </BottomSection>
    </FooterContainer>
  )
}

export default Footer
