import * as React from 'react'
import styled from 'styled-components'
import theme from '../../atoms/theme'
import Button from '../../molecules/button'

const newsletterImage = require('../../../assets/newsletter.svg') as string

interface Props {
  onClose: () => void
}

const StyledHeader = styled.h2`
  margin-bottom: 2.5rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    font-size: 2rem;
  }
`

const StyledImage = styled.img`
  height: 17.6rem;
  width: 24.8rem;
`

const buttonStyle = {
  height: '4.4rem',
  backgroundColor: theme.colours.white,
  color: theme.colours.quaternary,
  borderRadius: '0.8rem',
  border: `0.2rem solid ${theme.colours.quaternary}`,
  marginTop: '3.2rem',
}

const NewsletterConfirmation = (props: Props): JSX.Element => {
  return (
    <React.Fragment>
      <StyledHeader> Thanks for subscribing!</StyledHeader>
      <StyledImage src={newsletterImage} alt='Newsletter' />
      <div>
        You’re now subscribed to the newsletter!
        <br />
        You’ll hear from us shortly.
      </div>
      <Button style={buttonStyle} onClick={props.onClose}>
        {' '}
        Done!
      </Button>
    </React.Fragment>
  )
}

export default NewsletterConfirmation
