import * as React from 'react'
import theme from '../theme'

interface Props {
  colour?: string
}

const PBTArrowIcon = (props: Props): JSX.Element => {
  return (
    <svg width='12' height='16' xmlns='http://www.w3.org/2000/svg'>
      <title>Arrow</title>
      <path
        fill={props.colour ? props.colour : theme.colours.black}
        fillRule='evenodd'
        d='M9 5.333L6 2.667 3 0 0 2.667l3 2.666L0 8l3 2.667-3 2.666L3 16l3-2.667 3-2.666L12 8z'
      />
    </svg>
  )
}

export default PBTArrowIcon
