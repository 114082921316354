import * as React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import theme from '../components/atoms/theme'
import UnorderedList from '../components/atoms/unordered-list'
import Layout from '../components/layout'
import Header from '../components/molecules/header'
import RegistrationForm from '../components/organisms/registration-form/registration-form'
import StepBlock from '../components/organisms/registration-form/step-block'
import ThankYou from '../components/organisms/registration-form/thank-you-section'

const BenefitsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    flex-direction: column;
    & ul {
      padding: 0;
    }
  }
`

const BenefitsTitle = styled.h2`
  flex: 0 0 50%;
`
const FormContainer = styled.div`
  padding-top: 9rem;
  margin-top: -9rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    padding-top: 6rem;
    margin-top: 6rem;
  }
`
interface Props {
  showThankYou?: boolean
  location: {
    pathname: string
  }
}

const JoinCommunity = (props: Props): JSX.Element => {
  return (
    <Layout currentPath={props.location.pathname}>
      <Helmet title='Join community' />
      <Header title='Your journey to a stronger future starts here' />
      <BenefitsContainer>
        <BenefitsTitle>
          Join us, and get access to the support your business needs to thrive
        </BenefitsTitle>
        <UnorderedList
          list={[
            'Become a part of a community of like-minded Pacific business owners who support each other and grow together.',
            'Join events and workshops designed to help you strengthen skills, access new knowledge and grow your network.',
            'Leverage a network of trusted service providers who can offer specialised support for your business.',
            'Are you eligible? To apply, at least 50% of your company needs to be owned by a Pacific person who holds either New Zealand residency or citizenship.',
          ]}
        />
      </BenefitsContainer>
      <FormContainer id='community-form'>
        <h2>Two Easy Steps</h2>
        <StepBlock
          step='1. Fill out the Application Form'
          details='Fill out the application form and submit it. You will get a copy on your email.'
        />
        {props.showThankYou ? <ThankYou /> : <RegistrationForm />}
        <StepBlock
          step='2. Phone interview'
          details='We will get in touch with you to get to know you better and direct you to the most relevant programme.'
        />
      </FormContainer>
    </Layout>
  )
}

export default JoinCommunity
