import React, { ChangeEvent, useState } from 'react'
import styled from 'styled-components'
import theme from '../atoms/theme'

interface Props {
  label: string
  id?: string
  showError?: boolean
  onChange?: (date: string) => void
}

interface InputStyleProps {
  showError: boolean
}

const Label = styled.label`
  display: block;
  font-family: ${theme.fonts.secondary};
  font-size: 1.8rem;
  padding-bottom: 1.6rem;
`

const CustomInput = styled.input`
  border: 2px solid
    ${(props: InputStyleProps): string =>
      props.showError ? theme.colours.danger1 : theme.colours.black};
  border-radius: 0.4rem;
  height: 4rem;
  font-family: ${theme.fonts.secondary};
  font-size: 1.8rem;
  padding-left: 1.6rem;
  width: 100%;
  &::placeholder {
    font-size: 1.6rem;
  }
`

const Container = styled.div`
  flex: 1;
`

const checkValue = (inputStr, max): string => {
  let str = inputStr
  if (str.charAt(0) !== '0' || str === '00') {
    let num = Number.parseInt(str)
    if (Number.isNaN(num) || num <= 0 || num > max) {
      num = 1
    }
    str =
      num > Number.parseInt(max.toString().charAt(0)) &&
      num.toString().length === 1
        ? `0${num}`
        : num.toString()
  }
  return str
}

const DateInput = (props: Props): JSX.Element => {
  const [inputValue, setInputValue] = useState('')

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    let dateValue = event.target.value
    if (/\D\/$/.test(dateValue)) {
      dateValue = dateValue.substr(0, dateValue.length - 3)
    }

    const dateValuesSplit = dateValue
      .split('/')
      .map((v) => v.replace(/\D/g, ''))
    if (dateValuesSplit[0])
      dateValuesSplit[0] = checkValue(dateValuesSplit[0], 31)
    if (dateValuesSplit[1])
      dateValuesSplit[1] = checkValue(dateValuesSplit[1], 12)
    const output = dateValuesSplit.map((v, i) =>
      v.length === 2 && i < 2 ? `${v} / ` : v,
    )
    dateValue = output.join('').substr(0, 14)
    setInputValue(dateValue)

    if (props.onChange) {
      props.onChange(dateValue)
    }
  }

  return (
    <Container>
      <Label>{props.label}</Label>
      <CustomInput
        id={props.id}
        name={props.id}
        onChange={handleOnChange}
        showError={props.showError}
        placeholder='DD/MM/YYYY'
        type='text'
        value={inputValue}
      />
    </Container>
  )
}

export default DateInput
