import React, { ChangeEvent } from 'react'
import Input from '../../../atoms/input'
import Row from '../../../atoms/row'
import Separator from '../../../atoms/separator'

interface Props {
  handleAddressChange: (event: ChangeEvent<HTMLInputElement>) => void
  handleSuburbChange: (event: ChangeEvent<HTMLInputElement>) => void
  handleCityChange: (event: ChangeEvent<HTMLInputElement>) => void
  handlePostcodeChange: (event: ChangeEvent<HTMLInputElement>) => void
  errors: {
    manualStreet: string
    manualProvince: string
    manualCity: string
    manualPostcode: string
    googleAddress: string
  }
  onBlur: (value: string, key: string) => void
}

const ManualEntry = (props: Props): JSX.Element => {
  return (
    <React.Fragment>
      <Row>
        <Input
          onChange={props.handleAddressChange}
          label='Address*'
          placeholder='NZ address'
          errorMessage={props.errors.manualStreet}
          onBlur={(event: ChangeEvent<HTMLInputElement>): void => {
            if (props.onBlur) {
              props.onBlur(event.target.value, 'manualStreet')
            }
          }}
        />
        <Separator />
        <Input
          onChange={props.handleSuburbChange}
          label='Suburb*'
          placeholder='Type your suburb'
          errorMessage={props.errors.manualProvince}
          onBlur={(event: ChangeEvent<HTMLInputElement>): void => {
            if (props.onBlur) {
              props.onBlur(event.target.value, 'manualProvince')
            }
          }}
        />
      </Row>
      <Row>
        <Input
          onChange={props.handleCityChange}
          label='City*'
          placeholder='Enter city'
          errorMessage={props.errors.manualCity}
          onBlur={(event: ChangeEvent<HTMLInputElement>): void => {
            if (props.onBlur) {
              props.onBlur(event.target.value, 'manualCity')
            }
          }}
        />
        <Separator />
        <Input
          onChange={props.handlePostcodeChange}
          label='Postcode*'
          placeholder='Enter postal code'
          errorMessage={props.errors.manualPostcode}
          onBlur={(event: ChangeEvent<HTMLInputElement>): void => {
            if (props.onBlur) {
              props.onBlur(event.target.value, 'manualPostcode')
            }
          }}
        />
      </Row>
    </React.Fragment>
  )
}

export default ManualEntry
