import React, { ChangeEvent } from 'react'
import RadioGroup from '../../atoms/radio-group'
import Row from '../../atoms/row'
import FormSection from './form-section'

interface Props {
  handleIsResidentQuestion: (event: ChangeEvent<HTMLInputElement>) => void
}

const NZResidencySection = (props: Props): JSX.Element => {
  return (
    <FormSection>
      <h3>Business Owner</h3>
      <div>
        <Row>
          <RadioGroup
            name='nz-resident'
            label='Are you a New Zealand citizen or permanent resident?*'
            onChange={props.handleIsResidentQuestion}
            options={['yes', 'no']}
          />
        </Row>
      </div>
    </FormSection>
  )
}

export default NZResidencySection
