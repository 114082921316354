import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import * as React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import ArrowRightIcon from '../components/atoms/graphics/arrow-right'
import theme from '../components/atoms/theme'
import Layout from '../components/layout'
import Button from '../components/molecules/button'
import Header from '../components/molecules/header'

interface Props {
  location: {
    pathname: string
  }
}

interface ServiceProps {
  id?: string
  title: string
  descriptionNew: {
    descriptionNew: string
  }
  imagePlacement: number
  image: string
  buttonLabel: string
  buttonUrl: string
}

interface StyleProps {
  order?: number
}

const Description = styled.h3`
  max-width: 66%;
  text-align: left;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    text-align: left;
    max-width: 100%;
    margin-bottom: 2.7rem;
    font-size: 1.9rem;
  }
`

const ServicesContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    flex-direction: row;
    align-items: flex-start;
  }
`

const ServiceContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0;
  max-width: 100%;
  align-items: center;
  justify-content: space-between;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    max-width: 100%;
    flex: 1;
    flex-direction: column;
    margin-bottom: 2.8rem;
  }
`

const ServiceTitle = styled.h2`
  margin-bottom: 0.8rem;
  color: ${theme.colours.secondary};
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    font-size: 2.1rem;
  }
`

const Line = styled.div`
  width: 100%;
  height: 0.1rem;
  background-color: ${theme.colours.gray4};
`

const ServiceImgContainer = styled.div`
  width: 48%;
  order: ${(props: StyleProps): number => props.order};
  padding: 20px;
  height: 40rem;
  display: flex;
  align-items: center;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    width: 100%;
    order: 1;
    height: auto;
    padding: 2rem 0;
  }
`

const ServiceImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const ServiceContent = styled.div`
  width: 48%;
  order: 1;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    width: 100%;
    order: 2;
  }
`

const ServiceDescription = styled.div`
  padding-top: 2rem;
  padding-bottom: 3rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    padding-top: 1.5rem;
    padding-bottom: 2.5rem;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const StyledButton = styled(Button)`
  padding: 10px 24px;
  height: 4.4rem;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  min-width: unset;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    justify-content: center;
  }
`

export const ServiceBlock = (props: ServiceProps): JSX.Element => {
  return (
    <ServiceContainer>
      {props.image && (
        <ServiceImgContainer order={props.imagePlacement}>
          <ServiceImg src={props.image} alt={props.title} />
        </ServiceImgContainer>
      )}
      <ServiceContent>
        <ServiceTitle>{props.title}</ServiceTitle>
        <ServiceDescription>
          {props.descriptionNew.descriptionNew}
        </ServiceDescription>
        {props.buttonLabel && (
          <StyledLink to={props.buttonUrl}>
            <StyledButton>
              {props.buttonLabel}
              <ArrowRightIcon colour={theme.colours.white} />
            </StyledButton>
          </StyledLink>
        )}
      </ServiceContent>
    </ServiceContainer>
  )
}

const Services = (props: Props): JSX.Element => {
  const siteTitle = get(props, 'data.site.siteMetadata.title')
  const currentPath = get(props, 'location.pathname')
  const allPages = get(props, 'data.allContentfulPage.nodes')
  const allServices = get(
    props,
    'data.allContentfulFlexibleTextAndImageBlock.nodes',
  )
  const currentPage = allPages.find((page) => get(page, 'slug') === currentPath)
  const description = currentPage ? currentPage.description.description : null

  const getImagePlacement = (imagePlacement: string): number => {
    return imagePlacement === 'right' ? 2 : 1
  }

  return (
    <Layout currentPath={currentPath}>
      <Helmet title={siteTitle} />
      <Header title={currentPage.title} />
      {description && <Description>{description}</Description>}
      {allServices.map((service) => {
        return (
          <React.Fragment key={service.id}>
            <ServicesContainer>
              <ServiceBlock
                key={service.id}
                title={service.title}
                descriptionNew={service.descriptionNew}
                imagePlacement={getImagePlacement(service.imagePlacement)}
                buttonLabel={service.buttonLabel}
                buttonUrl={service.buttonUrl}
                image={service.image.file.url}
              />
            </ServicesContainer>
          </React.Fragment>
        )
      })}
    </Layout>
  )
}

export default Services

export const pageQuery = graphql`
  query ServicesQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulPage {
      nodes {
        title
        slug
        description {
          description
        }
      }
    }
    allContentfulFlexibleTextAndImageBlock(sort: { fields: [position], order: ASC }) {
      nodes {
        id
        title
        position
        buttonLabel
        buttonUrl
        imagePlacement
        descriptionNew {
          descriptionNew
        }
        image {
          file {
            url
          }
        }
      }
    }
  }
`
