import { Link } from 'gatsby'
import * as React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import theme from '../components/atoms/theme'
import Layout from '../components/layout'

const desktop404Image = require('../assets/loading.svg') as string
const mobile404Image = require('../assets/loading.svg') as string

const Body = styled.div`
  display: flex;
  margin-top: 8.2rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    flex-direction: column;
    align-items: center;
    margin-top: 4.5rem;
  }
`

const DesktopImage = styled.img`
  width: 40rem;
  margin-right: 9.4rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    display: none;
  }
`

const MobileImage = styled.img`
  width: 23.4rem;
  @media only screen and ${theme.breakpoints.fromLargeScreen} {
    display: none;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    margin-top: 4rem;
  }
`

const Title = styled.h3`
  margin-bottom: 2.8rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    margin-bottom: 1.6rem;
  }
`

const Text = styled.p`
  margin-top: 0;
`

const Error404 = (): JSX.Element => {
  return (
    <Layout>
      <Helmet title='Page not found' />
      <Body>
        <DesktopImage src={desktop404Image} alt='404' />
        <MobileImage src={mobile404Image} alt='404' />
        <TextContainer>
          <Title>Something went wrong</Title>
          <Text>
            Sorry, we have recently re-organised the site and the page you were
            looking for appears to have been moved, deleted or does not exist.
          </Text>
          <Link to='/'>Back to Homepage</Link>
        </TextContainer>
      </Body>
    </Layout>
  )
}

export default Error404
