import moment from 'moment'
import * as React from 'react'
import styled from 'styled-components'
import ArrowRight from '../../atoms/graphics/arrow-right'
import CalendarIcon from '../../atoms/graphics/calendar-icon'
import MapPinIcon from '../../atoms/graphics/map-pin-icon'
import UsersIcon from '../../atoms/graphics/users-icon'
import theme from '../../atoms/theme'
import EventCapacity from './event-capacity'
import EventSocialLinks from './event-social-links'

interface Props {
  data: {
    title: string
    description: string
    shortDescription: string
    startDate: string
    endDate: string
    locationName: string
    locationStreet: string
    locationCity: string
    locationPostCode: string
    locationCountry: string
    imageUrl: string
    eventType?: string
    registrationUrl: string
    seatingStatus: string
    detailedEventType: string
  }
  path: string
}

const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  flex-wrap: wrap;
  min-height: 57rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    flex-direction: column;
  }
`

const LeftContainer = styled.div`
  width: 60%;
  border-bottom: 1px solid ${theme.colours.gray4};
  padding-bottom: 3rem;
  margin-top: -2rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    width: 100%;
    order: 3;
    margin-top: 0;
    h3 {
      font-size: 1.9rem;
    }
  }
`
const RightContainer = styled.div`
  width: 25%;
  position: absolute;
  right: 0;
  top: -17.5rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    width: 100%;
    order: 1;
    position: relative;
    top: 0;
    margin-bottom: -2rem;
  }
`

const DetailContainer = styled.div`
  background-color: ${theme.colours.gray4};
  padding: 3rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    background-color: ${theme.colours.white};
    padding: 0;
  }
`

const DetailSection = styled.div`
  border-bottom: 1px solid ${theme.colours.gray3};
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  line-height: 1.25;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    padding-bottom: 0.5rem;
    margin: 0;
    border-bottom: 0;
  }
`

const DetailsHeader = styled.span`
  font-family: ${theme.fonts.primaryBold};
  padding-left: 1rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    display: none;
  }
`

const DetailsDescription = styled.div`
  font-family: ${theme.fonts.primary};
  font-size: 1.6rem;
  padding: 1rem 0;
  width: 100%;
  padding-bottom: 0;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    font-size: 1.4rem;
    width: 85%;
    margin-left: 1rem;
    padding: 0.5rem 0 0.5rem 0;
    font-family: ${theme.fonts.primaryBold};
    color: ${theme.colours.secondary};
    span:first-child {
      display: none;
    }
  }
`

const EventType = styled.span`
  display: inline-block;
  margin-bottom: 3rem;
  border-radius: 0.6rem;
  border: 1px solid ${theme.colours.secondary};
  padding: 0.5rem 1rem;
  color: ${theme.colours.secondary};
  font-family: ${theme.fonts.primarySemiBold};
  font-size: 1.6rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    display: none;
  }
`

const DetailsLink = styled.a`
  color: ${theme.colours.quaternary};
  font-size: 1.6rem;
  font-family: ${theme.fonts.primaryBold};
  text-decoration: underline;
  margin-bottom: 2rem;
  padding-top: 1rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    display: none;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  ${DetailSection} & {
    align-items: flex-start;
  }
`

const RegisterContainer = styled(Row)`
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    display: none;
  }
`

const StyledImg = styled.img`
  padding-bottom: 3rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    display: none;
  }
`

const RegisterLink = styled.a`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 0;
  margin-top: 4rem;
  background-color: ${theme.colours.quaternary};
  border-radius: 0.8rem;
  color: ${theme.colours.white};
  font-family: ${theme.fonts.primaryBold};
  font-size: 1.6rem;
  text-transform: uppercase;
  svg {
    margin-left: 0.5rem;
  }
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    padding: 1rem 0;
    margin-top: 0;
  }
`

const MobileRegisterContainer = styled(RegisterContainer)`
  display: none;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    order: 2;
    display: block;
    position: sticky;
    top: 6rem;
    padding: 2rem 0;
    margin: 2rem 0;
    background-color: ${theme.colours.white};
  }
`

const SocialContainer = styled.div`
  padding-top: 1.2rem;
  width: 60%;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    width: 100%;
    order: 4;
  }
`

const CapacityContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    display: none;
  }
`

const Time = styled.span`
  display: block;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    display: inline;
    padding-left: 0.5rem;
    border-left: solid 1px ${theme.colours.gray1};
    margin-left: 0.5rem;
  }
`

const EventDescription = styled.div`
  white-space: pre-line;
`

const EventDetail = (props: Props): JSX.Element => {
  const location = `${props.data.locationName},${props.data.locationStreet}, ${props.data.locationCity}, ${props.data.locationPostCode}, ${props.data.locationCountry}`
  const showLocation =
    props.data.locationName ||
    props.data.locationStreet ||
    props.data.locationCity
  const goolgeMapUrl = `https://maps.google.com/?q=${location}`
  const startDate = new Date(props.data.startDate)
  const endDate = new Date(props.data.endDate)

  const getDate = (): string => {
    const weekday = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]
    return `${weekday[startDate.getDay()]}, ${startDate.getDate()} ${moment(startDate).format('MMMM')}`
  }

  const getYear = (): string => {
    return moment(startDate).format('YYYY')
  }

  const getTime = (date): string => {
    return moment(date).format('h:mm A')
  }

  return (
    <React.Fragment>
      <BodyContainer>
        <LeftContainer>
          {props.data.detailedEventType && (
            <EventType> {props.data.detailedEventType} </EventType>
          )}
          <h3>{props.data.shortDescription}</h3>
          <EventDescription>{props.data.description}</EventDescription>
        </LeftContainer>

        <RightContainer>
          <StyledImg src={props.data.imageUrl} />
          <DetailContainer>
            {props.data.startDate && props.data.endDate && (
              <DetailSection>
                <Row>
                  <CalendarIcon /> <DetailsHeader>When</DetailsHeader>
                  <DetailsDescription>
                    {getDate()} <span>{getYear()}</span>
                    <Time>
                      {getTime(startDate)} – {getTime(endDate)}
                    </Time>
                  </DetailsDescription>
                </Row>
              </DetailSection>
            )}
            {showLocation && (
              <DetailSection>
                <Row>
                  <MapPinIcon /> <DetailsHeader>Where</DetailsHeader>
                  <DetailsDescription>
                    {props.data.locationName && (
                      <React.Fragment>
                        {props.data.locationName} <br />
                      </React.Fragment>
                    )}
                    {props.data.locationStreet && (
                      <React.Fragment>
                        {props.data.locationStreet}
                        <br />
                      </React.Fragment>
                    )}
                    {props.data.locationCity}
                    {props.data.locationPostCode && props.data.locationCity && (
                      <React.Fragment>
                        , {props.data.locationPostCode}
                        <br />
                      </React.Fragment>
                    )}
                    {props.data.locationCountry}
                  </DetailsDescription>
                </Row>
                {(props.data.locationName || props.data.locationStreet) && (
                  <DetailsLink target='_blank' href={goolgeMapUrl}>
                    {' '}
                    Google maps
                  </DetailsLink>
                )}
              </DetailSection>
            )}
            {props.data.seatingStatus && (
              <CapacityContainer>
                <div>
                  <UsersIcon /> <DetailsHeader>Capacity</DetailsHeader>
                </div>
                <EventCapacity seatingStatus={props.data.seatingStatus} />
              </CapacityContainer>
            )}
            {props.data.registrationUrl && (
              <RegisterContainer>
                <RegisterLink target='_blank' href={props.data.registrationUrl}>
                  Register <ArrowRight />
                </RegisterLink>
              </RegisterContainer>
            )}
          </DetailContainer>
        </RightContainer>

        <SocialContainer>
          <p>Share this workshop</p>
          <EventSocialLinks eventUrl={props.path} title={props.data.title} />
        </SocialContainer>

        {props.data.registrationUrl && (
          <MobileRegisterContainer>
            <RegisterLink target='_blank' href={props.data.registrationUrl}>
              Register <ArrowRight />
            </RegisterLink>
          </MobileRegisterContainer>
        )}
      </BodyContainer>
    </React.Fragment>
  )
}

export default EventDetail
