import * as React from 'react'
import theme from '../theme'

interface Props {
  colour?: string
}

const ArrowRightIcon = (props: Props): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
    >
      <title>Arrow Right</title>
      <path
        fill={props.colour ? props.colour : theme.colours.white}
        fillRule='evenodd'
        d='M12.707 4.293l7 7c.029.028.055.059.08.09l-.08-.09c.036.035.068.073.097.112.018.024.034.049.05.074l.021.037c.011.02.022.04.031.06l.023.053.021.06.014.045.016.065.009.053.007.06.003.056L20 12v.033l-.004.052L20 12c0 .05-.004.1-.011.149l-.01.052c-.004.022-.009.043-.015.065l-.014.046-.021.06-.023.051-.03.061-.022.037c-.016.025-.032.05-.05.074l-.017.022-.007.008-.073.082-7 7c-.39.39-1.024.39-1.414 0-.39-.39-.39-1.024 0-1.414L16.585 13H5c-.552 0-1-.448-1-1s.448-1 1-1h11.585l-5.292-5.293c-.39-.39-.39-1.024 0-1.414.39-.39 1.024-.39 1.414 0z'
      />
    </svg>
  )
}

export default ArrowRightIcon
