import React, { ReactNode, ChangeEvent, useState } from 'react'
import styled from 'styled-components'
import ErrorMessage from './error-message'
import theme from './theme'

interface RadioGroupProps {
  options: Array<string>
  label: string
  name: string
  isHorizontal?: boolean
  dataTestId?: string
  errorMessage?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onBlur?: (isChecked: boolean) => void
}

interface StyleProps {
  isHorizontal?: boolean
}

const RadioGroupContainer = styled.div`
  display: flex;
  flex-direction: ${(props: StyleProps): string =>
    props.isHorizontal ? 'row' : 'column'};
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    flex-direction: column;
  }
`

const CustomRadio = styled.div`
  position: relative;
  height: 4rem;
  width: 4rem;
  border: 0.2rem solid ${theme.colours.black};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &::before {
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background-color: ${theme.colours.quaternary};
    position: absolute;
    content: '';
    opacity: 0;
  }
`

const Title = styled.div`
  font-family: ${theme.fonts.secondary};
  font-size: 1.8rem;
  margin-bottom: 1.6rem;
`

const RadioContainer = styled.div`
  display: flex;
  width: fit-content;
  &:not(:last-child) {
    margin-bottom: ${(props: StyleProps): string =>
      props.isHorizontal ? '0' : '1.6rem'};
  }
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    &:not(:last-child) {
      margin-bottom: 1.6rem;
    }
  }
  align-items: center;
  flex-direction: row;
`

const Option = styled.div`
  font-family: ${theme.fonts.secondary};
  font-size: 1.8rem;
  padding-left: 1rem;
  text-transform: capitalize;
`

const RadioInput = styled.input`
  position: absolute;
  left: -999rem;
  &:focus + label > .custom-radio {
    box-shadow: 0 0 0.6rem ${theme.colours.secondary};
  }
  &:checked + label > .custom-radio::before {
    opacity: 1;
  }
`

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 9.4rem;
`

const RadioGroup = (props: RadioGroupProps): JSX.Element => {
  const [isChecked, setIsChecked] = useState(false)

  const handleOnBlur = (): void => {
    if (props.onBlur) {
      props.onBlur(isChecked)
    }
  }

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setIsChecked(true)
    if (props.onChange) {
      props.onChange(event)
    }
  }

  return (
    <div>
      <Title>{props.label}</Title>
      <RadioGroupContainer
        role='radiogroup'
        data-test-id={props.dataTestId}
        isHorizontal={props.isHorizontal}
      >
        {props.options.map((option: string): ReactNode => {
          return (
            <RadioContainer isHorizontal={props.isHorizontal} key={option}>
              <RadioInput
                onBlur={handleOnBlur}
                tabIndex={0}
                type='radio'
                value={option}
                name={props.name}
                id={`${props.name}-${option}`}
                onChange={handleOnChange}
              />
              <RadioLabel htmlFor={`${props.name}-${option}`}>
                <CustomRadio className='custom-radio' />
                <Option>{option}</Option>
              </RadioLabel>
            </RadioContainer>
          )
        })}
      </RadioGroupContainer>
      {props.errorMessage && <ErrorMessage>{props.errorMessage}</ErrorMessage>}
    </div>
  )
}

export default RadioGroup
