import * as React from 'react'
import theme from '../theme'

interface Props {
  active?: boolean
  colour?: string
}

const CommentIcon = (props: Props): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='24'
      viewBox='0 0 25 24'
    >
      <title>Comment</title>
      <path
        fill={
          props.active
            ? theme.colours.quaternary
            : props.colour
              ? props.colour
              : theme.colours.white
        }
        fillRule='evenodd'
        d='M22.437 10.9c-.3-4.8-4.092-8.7-8.982-8.9h-.5c-1.496 0-2.894.3-4.191 1-3.294 1.6-5.29 4.9-5.29 8.5 0 1.3.3 2.7.799 3.9l-1.797 5.3c-.1.4 0 .8.2 1 .3.2.499.3.798.3.1 0 .2 0 .3-.1l5.29-1.8c1.197.5 2.495.8 3.892.8 3.593 0 6.886-2 8.483-5.2.699-1.3.998-2.8.998-4.3v-.5zm-1.996.6c0 1.2-.3 2.3-.798 3.4-1.298 2.6-3.793 4.1-6.687 4.1-1.098 0-2.296-.3-3.294-.8-.2-.1-.499-.1-.798-.1L5.07 19.4l1.298-3.8c.1-.3.1-.5-.1-.8-.5-1-.799-2.2-.799-3.3 0-2.9 1.597-5.4 4.192-6.7.998-.5 2.196-.8 3.294-.8h.399c3.793.2 6.787 3.2 7.086 7v.5z'
      />
    </svg>
  )
}

export default CommentIcon
