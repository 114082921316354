import { Link } from 'gatsby'
import React, { useState } from 'react'
import styled from 'styled-components'
import SquiggleSVG from '../atoms/graphics/squiggle-svg'
import theme from '../atoms/theme'
import { LOGIN_URL } from '../constants'
import NavigationCTA from '../molecules/navigation-cta'
import NavigationItem from '../molecules/navigation-item'
import Notification from './notification'

const logoSVG = require('../../assets/pbt-logo-black.svg') as string
const Close = require('../../assets/close.svg') as string

const NavigationContainer = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.8rem;
  padding: 1rem 2rem;
  position: fixed;
  z-index: 999;
  background: ${theme.colours.white};
  min-height: 9rem;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    padding: 0;
    min-height: 6rem;
  }
`

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 144rem;
  padding: 0 2rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    padding: 0 1.6rem;
    min-height: 6rem;
  }
`

const Logo = styled.img`
  height: 4rem;
  width: auto;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    height: 3.2rem;
    width: auto;
  }
  flex: 0;
`

const NavigationItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    display: none;
  }
`

const MainLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 600;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
  }
`

const MobileMenuButtonContainer = styled.div`
  font-weight: 600;
  font-size: 1.2rem;
  font-family: ${theme.fonts.primaryBold};
  text-transform: uppercase;
  width: 6.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    height: 0.7rem;
  }
  @media only screen and ${theme.breakpoints.fromLargeScreen} {
    display: none;
  }
`

const MenuContainer = styled.div`
  max-height: 0;
  position: absolute;
  width: 100%;
  z-index: 2;
  background: ${theme.colours.white};
  top: 0;
  &.open {
    display: block;
    min-height: 100vh;
    margin-top: 6rem;
    margin-left: -1.6rem;
    overflow-y: scroll;
  }
`

const MobileNavMenu = styled.div`
  @media only screen and ${theme.breakpoints.fromLargeScreen} {
    display: none;
  }
  animation: fadein 0.2s;
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

const CloseIcon = styled.img`
  width: 1.6rem;
  height: 1.6rem;
`

const NavigationGroup = styled.div`
  border-left: 1px solid ${theme.colours.gray3};
  display: flex;
  align-items: baseline;
  margin-left: 1rem;
  padding-left: 1rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    border: none;
    flex-direction: column;
    align-items: center;
    margin-left: 0;
    padding-left: 0;
    width: 100%;
  }
`

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
`

interface Props {
  currentPath: string
}

const Navigation = (props: Props): JSX.Element => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const path = props.currentPath

  const servicesSubLinks = [
    { title: 'Programmes and Services', to: '/services/customised-support/' },
    { title: 'Procurement and Support', to: '/services/procurement/' },
    { title: 'Hatch Pacific', to: '/services/hatch-pacific/' },
  ]

  const mainLinks = (
    <MainLinks>
      <NavigationItem title='About' to='/about/' active={path === '/about/'} />
      <NavigationItem
        title='What We Deliver'
        to='/services/'
        active={path && path.indexOf('services') !== -1}
        subLinks={servicesSubLinks}
      />
      <NavigationItem
        title='Events'
        to='/events/'
        active={path === '/events/'}
      />
      <NavigationItem title='News' to='/news/' active={path === '/news/'} />
      <NavigationItem
        title='Resources'
        to='/resources/'
        active={path === '/resources/'}
      />
      <NavigationItem
        title='Contact'
        to='/contact/'
        active={['/contact/', '/contact-us-thank-you/'].includes(path)}
      />
      <NavigationGroup>
        <NavigationItem isExternal title='Log in' to={LOGIN_URL} />
        <NavigationCTA
          title='Join Community'
          to='/join-community/'
          active={['/join-community/', '/thank-you/'].includes(path)}
        />
      </NavigationGroup>
    </MainLinks>
  )

  return (
    <HeaderWrapper>
      <Notification isMenuActive={isMenuOpen} />
      <NavigationContainer role='navigation'>
        <Inner>
          <Link to='/'>
            <Logo data-test-id='logo' src={logoSVG} alt='logo' />
          </Link>
          <MobileMenuButtonContainer
            data-test-id='menu-button'
            onClick={(): void => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? (
              <CloseIcon alt='Close' data-test-id='close-button' src={Close} />
            ) : (
              <div>Menu</div>
            )}
            {!isMenuOpen && <SquiggleSVG small />}
          </MobileMenuButtonContainer>
          <NavigationItemsContainer>{mainLinks}</NavigationItemsContainer>
          <MenuContainer className={isMenuOpen ? 'open' : ''}>
            {isMenuOpen && (
              <MobileNavMenu data-test-id='mobile-nav-menu'>
                {mainLinks}
              </MobileNavMenu>
            )}
          </MenuContainer>
        </Inner>
      </NavigationContainer>
    </HeaderWrapper>
  )
}

export default Navigation
