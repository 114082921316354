import { Link } from 'gatsby'
import React, { ReactNode } from 'react'
import styled from 'styled-components'
import theme from '../atoms/theme'
import NavigationDropdown from './navigation-dropdown'

interface SubLink {
  title: string
  to: string
}

interface NavigationItemProps {
  title: string
  to: string
  active?: boolean
  isExternal?: boolean
  subLinks?: Array<SubLink>
  children?: ReactNode
}

const NavigationItemContainer = styled.li`
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  position: relative;
  border-bottom: 2px solid transparent;
  padding: 1.2rem 1.2rem;
  margin: 0 0.5rem;
  border-radius: 0.8rem;
  background-color: ${(props: NavigationItemProps): string =>
    props.active ? theme.colours.gray4 : theme.colours.white};
  cursor: pointer;
  &:hover {
    background-color: ${theme.colours.gray4};
    border-radius: 0.8rem;
  }
  a {
    font-size: 1.6rem;
    color: ${theme.colours.black};
  }
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    margin-bottom: 2.5rem;
    margin-top: 0;
  }
`

const NavDropdownContainer = styled.div`
  display: none;
  position: absolute;
  top: 5rem;
  left: 0;
  ${NavigationItemContainer}:hover & {
    display: block;
  }
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    ${NavigationItemContainer}:hover & {
      display: none;
    }
  }
`

const NavigationItem = (props: NavigationItemProps): JSX.Element => {
  return (
    <React.Fragment>
      <NavigationItemContainer
        data-test-id={`navitem-${props.title.toLowerCase()}`}
        {...props}
      >
        {props.isExternal ? (
          <a style={{ textDecoration: 'none' }} href={props.to}>
            {props.title}
          </a>
        ) : (
          <Link style={{ textDecoration: 'none' }} to={props.to}>
            {props.title}
          </Link>
        )}
        {props.subLinks && (
          <NavDropdownContainer>
            <NavigationDropdown subLinks={props.subLinks} />
          </NavDropdownContainer>
        )}
      </NavigationItemContainer>
    </React.Fragment>
  )
}

export default NavigationItem
