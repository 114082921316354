import * as React from 'react'
import styled from 'styled-components'
import theme from '../atoms/theme'
import Carousel from '../molecules/carousel'
import NewsArticlePreview from './news-article-preview'

const NewsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    display: none;
  }
`

const MobileView = styled.div`
  display: none;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    display: block;
  }
`

interface Article {
  id: string
  title: string
  slug: string
  previewText: string
  date: string
  isFeatured: boolean
  image: {
    fluid
  }
  body: {
    json: string
  }
}

interface Props {
  news: Array<Article>
}

const FeaturedNewsRow = (props: Props): JSX.Element => {
  const articles = props.news
    ? props.news.map((article: Article) => {
        return <NewsArticlePreview key={article.id} {...article} />
      })
    : []

  return (
    <React.Fragment>
      <NewsContainer>{articles}</NewsContainer>
      <MobileView>
        <Carousel carouselItems={articles} />
      </MobileView>
    </React.Fragment>
  )
}

export default FeaturedNewsRow
