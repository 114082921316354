import * as React from 'react'
import styled, { keyframes } from 'styled-components'

const spinnerWhite = require('../../assets/spinner-white-icon.svg') as string
const spinnerAlternative =
  require('../../assets/spinner-alternative-icon.svg') as string

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const SpinnerComponent = styled.img`
  animation: ${rotate} 2s infinite linear;
`

interface Props {
  style?: object
  alternative?: boolean
}

const Spinner = (props: Props): JSX.Element => {
  return (
    <SpinnerComponent
      style={props.style}
      src={props.alternative ? spinnerAlternative : spinnerWhite}
      alt='Squiggle'
    />
  )
}

export default Spinner
