import * as React from 'react'
import theme from '../theme'

interface Props {
  active?: boolean
  colour?: string
}

const PhoneIcon = (props: Props): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='24'
      viewBox='0 0 25 24'
    >
      <title>Phone</title>
      <path
        fill={
          props.active
            ? theme.colours.quaternary
            : props.colour
              ? props.colour
              : theme.colours.white
        }
        fillRule='evenodd'
        d='M20.825 13.959c-.904-.1-1.808-.301-2.612-.603-1.105-.402-2.31-.1-3.214.703l-.703.704c-1.809-1.206-3.416-2.713-4.621-4.621l.703-.704c.803-.803 1.105-2.11.703-3.214-.301-.804-.502-1.708-.603-2.612C10.278 2.105 8.971 1 7.465 1H4.15c-.804.1-1.507.502-2.01 1.105-.502.603-.703 1.406-.703 2.21.302 3.215 1.507 6.43 3.215 9.142 1.607 2.511 3.817 4.721 6.329 6.328 2.712 1.809 5.927 2.914 9.141 3.215h.301c.804 0 1.608-.301 2.11-.904.502-.603.904-1.306.904-2.11v-3.013c0-1.507-1.105-2.813-2.612-3.014zm.603 3.014v3.013c0 .302-.1.503-.301.703-.201.201-.402.302-.804.302-2.913-.302-5.726-1.306-8.237-2.913-2.31-1.407-4.22-3.416-5.726-5.726C4.752 9.84 3.748 7.027 3.446 4.114c0-.301.1-.502.201-.703.201-.201.503-.402.804-.402h3.014c.502 0 .904.402 1.004.904.1 1.005.402 2.11.804 3.014.1.402 0 .804-.201 1.105L7.766 9.237c-.301.302-.402.804-.201 1.206 1.507 2.712 3.717 4.922 6.43 6.43.401.2.903.2 1.205-.202l1.306-1.306c.301-.301.703-.402 1.105-.2 1.004.401 2.009.602 3.013.803.402.1.804.502.804 1.005z'
      />
    </svg>
  )
}

export default PhoneIcon
