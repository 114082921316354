import * as React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import theme from '../components/atoms/theme'
import Layout from '../components/layout'
import Header from '../components/molecules/header'
import RegistrationSteps from '../components/organisms/sp-registration-form/registration-steps'

interface Props {
  location: {
    pathname: string
  }
}

const ThankYouContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const BodyText = styled.h3`
  max-width: 80%;
  text-align: left;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    text-align: left;
    max-width: 100%;
    margin-bottom: 3.7rem;
    font-size: 1.9rem;
  }
`

const ThankYou = (props: Props): JSX.Element => {
  return (
    <Layout currentPath={props.location.pathname}>
      <Helmet title={'Thanks for registering your interest'} />
      <Header title={'Thanks for registering your interest'} />
      <ThankYouContainer>
        <BodyText>
          Thanks for registering your interest in becoming a Preferred Service
          Provider on the Pacific Business Trust network. We carry out regular
          intakes of Service Providers to be part of our network – we will be in
          touch with you before the next intake commences.
        </BodyText>
        <BodyText>Here are the next steps:</BodyText>
        <RegistrationSteps checkedSteps={1} />
      </ThankYouContainer>
    </Layout>
  )
}

export default ThankYou
