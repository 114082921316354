import * as React from 'react'
import styled from 'styled-components'
import theme from '../atoms/theme'
import NavigationItem from './navigation-item'

const NavDropdownContainer = styled.ul`
  display: flex;
  flex-direction: column;
  z-index: 1;
  background-color: ${theme.colours.white};
  border-radius: 0 0 0.8rem 0.8rem;
  border-bottom: 0.5rem solid ${theme.colours.primary};
  padding-left: 0;
  margin-top: 0.5rem;
  width: 24rem;
  box-shadow: 0 2.4rem 4.8rem 0 rgba(0, 0, 0, 0.16);
`

interface SubLink {
  title: string
  to: string
}
interface Props {
  subLinks: Array<SubLink>
}

const NavigationDropdown = (props: Props): JSX.Element => {
  return (
    <NavDropdownContainer>
      {props.subLinks.map((sublink) => {
        return (
          <NavigationItem
            key={sublink.to}
            title={sublink.title}
            to={sublink.to}
          />
        )
      })}
    </NavDropdownContainer>
  )
}

export default NavigationDropdown
