import { graphql } from 'gatsby'
import get from 'lodash/get'
import orderBy from 'lodash/orderBy'
import React, { useState, useEffect } from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import Spinner from '../../components/atoms/spinner'
import theme from '../../components/atoms/theme'
import { LAMBDA_URL } from '../../components/constants'
import Layout from '../../components/layout'
import Breadcrumbs from '../../components/molecules/breadcrumbs'
import Header from '../../components/molecules/header'
import EmptyEvents from '../../components/organisms/events/empty-events'
import EventBlock from '../../components/organisms/events/event-block'
import EventFilters from '../../components/organisms/events/event-filter'

interface Props {
  location: {
    pathname: string
  }
}

const Description = styled.h3`
  max-width: 80%;
  text-align: left;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    text-align: left;
    max-width: 100%;
    margin-bottom: 3.7rem;
    font-size: 1.9rem;
  }
`

const SpinnerContainer = styled.div`
  padding: 8rem;
`

const spinnerStyle = {
  height: '20rem',
}

const Workshops = (props: Props): JSX.Element => {
  const siteTitle = get(props, 'data.site.siteMetadata.title')
  const currentPath = get(props, 'location.pathname')
  const allPages = get(props, 'data.allContentfulPage.nodes')
  const currentPage = allPages.find((page) => get(page, 'slug') === currentPath)
  const description = currentPage ? currentPage.description.description : null
  const [workshopData, setWorkshopData] = useState(null)
  const [isLoading, setLoading] = useState(true)
  const [filteredData, setData] = useState(null)
  const [isEmpty, setEmptyWorkshops] = useState(true)

  const query = `
    query {
      events(
        filter: {
          eventType: "Workshop Event"
          isPublished: true
          isCurrent: true
        }
      ) {
        id
        title
        shortDescription
        eventType
        startDate
        endDate
        imageUrl
        locationCity
        seatingStatus
        detailedEventType
      }
    }
  `

  useEffect(() => {
    fetch(LAMBDA_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ query }),
    })
      .then((response) => response.json())
      .then((resultData) => {
        if (resultData.data.events.length > 0) {
          const data = orderBy(resultData.data.events, ['startDate'], ['asc'])
          setWorkshopData(data)
          setData(data)
          setEmptyWorkshops(false)
        } else {
          setEmptyWorkshops(true)
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        setEmptyWorkshops(true)
      })
  }, [])

  const handleSetData = (data): void => {
    setData(data)
  }

  return (
    <Layout currentPath={currentPath}>
      <Helmet title={siteTitle} />
      <Header title={currentPage.title}>
        <Breadcrumbs
          crumbs={[
            {
              label: 'Services',
              path: '/services/',
            },
            {
              label: 'Workshops',
              isActive: true,
            },
          ]}
        />
      </Header>
      {description && <Description>{description}</Description>}
      {isLoading ? (
        <SpinnerContainer>
          <Spinner style={spinnerStyle} alternative={true} />
        </SpinnerContainer>
      ) : !isEmpty ? (
        <React.Fragment>
          <EventFilters
            data={workshopData}
            filteredData={filteredData}
            setData={handleSetData}
          />
          <div>
            {/* biome-ignore lint/suspicious/noExplicitAny: <explanation> */}
            {(filteredData as any[])?.map((workshop, index) => {
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              return <EventBlock data={workshop} key={index} />
            })}
          </div>
        </React.Fragment>
      ) : (
        <EmptyEvents isWorkshop={true} />
      )}
    </Layout>
  )
}

export default Workshops

export const pageQuery = graphql`
  query WorkshopsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulPage {
      nodes {
        title
        slug
        description {
          description
        }
      }
    }
  }
`
