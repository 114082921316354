import * as React from 'react'
import styled from 'styled-components'
import DateInput from '../components/atoms/date-input'
import Input from '../components/atoms/input'
import OrderedList from '../components/atoms/ordered-list'
import RadioGroup from '../components/atoms/radio-group'
import Select from '../components/atoms/select'
import theme from '../components/atoms/theme'
import UnorderedList from '../components/atoms/unordered-list'
import Layout from '../components/layout'
import Button from '../components/molecules/button'
import Section from '../components/style-guide-section'
import Swatch from '../components/swatch'

const Container = styled.div`
  padding: 4rem 0;
`

const Header = styled.div`
  border-bottom: 1px solid #ddd;
`

const Separator = styled.div`
  height: 4rem;
`

const ColourSwatches = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

const SwatchRow = styled.div`
  display: flex;
`

const ButtonBackground = styled.div`
  padding: 4rem;
  display: inline-block;
  margin-right: 1rem;
  background: ${theme.colours.secondary};
`

const InputContainer = styled.div`
  width: 40rem;
`

const buttonStyle = {
  marginRight: '1rem',
}

const StyleGuide = (): JSX.Element => {
  return (
    <Layout>
      <div style={{ background: '#fff' }}>
        <Container>
          <Header data-test-id='style-guide-header'>
            <h2>Style Guide</h2>
            <p>
              A working site style guide built to manage design and code quality
              control.
            </p>
          </Header>
          <Section title='Colours'>
            <ColourSwatches>
              <SwatchRow>
                <Swatch title='Primary' colour={theme.colours.primary} />
                <Swatch
                  title='Primary - Hover'
                  colour={theme.colours.primaryHover}
                />
                <Swatch title='Secondary' colour={theme.colours.secondary} />
                <Swatch
                  title='Secondary - Hover'
                  colour={theme.colours.secondaryHover}
                />
              </SwatchRow>
              <SwatchRow>
                <Swatch title='Tertiary' colour={theme.colours.tertiary} />
                <Swatch
                  title='Tertiary - Hover'
                  colour={theme.colours.tertiaryHover}
                />
                <Swatch title='Quaternary' colour={theme.colours.quaternary} />
                <Swatch
                  title='Quaternary - Hover'
                  colour={theme.colours.quaternaryHover}
                />
              </SwatchRow>
              <SwatchRow>
                <Swatch title='Gray 1' colour={theme.colours.gray1} darkText />
                <Swatch title='Gray 2' colour={theme.colours.gray2} darkText />
                <Swatch title='Gray 3' colour={theme.colours.gray3} darkText />
                <Swatch title='Gray 4' colour={theme.colours.gray4} darkText />
              </SwatchRow>
              <SwatchRow>
                <Swatch title='Black' colour={theme.colours.black} />
                <Swatch title='White' colour={theme.colours.white} darkText />
              </SwatchRow>
            </ColourSwatches>
          </Section>
          <Section title='Typography'>
            <h1>Heading 1</h1>
            <h2>Heading 2</h2>
            <h3>Heading 3</h3>
            <h4>Heading 4</h4>
            <h5>Heading 5</h5>
            <h6>Heading 6</h6>
            <p>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
              nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam
              erat volutpat.
            </p>
            <p>
              Ut wisi enim ad minim veniam, quis nostrud{' '}
              <strong>exerci tation ullamcorper suscipit </strong>lobortis nisl
              ut aliquip ex ea commodo consequat. Duis autem vel eum iriure
              dolor in hendrerit <em>in vulputate velit esse molestie </em>
              consequat, vel illum dolore eu feugiat nulla facilisis at vero
              eros et accumsan et iusto odio dignissim qui blandit praesent
              luptatum zzril delenit augue duis dolore te feugait nulla
              facilisi. Nam liber tempor cum soluta nobis eleifend option
              congue.
            </p>
            <p>
              Nihil imperdiet doming id{' '}
              {/* biome-ignore lint/a11y/useValidAnchor: <explanation> */}
              <a href='#' title='Link'>
                quod mazim placerat
              </a>{' '}
              facer possim assum. Typi non habent claritatem insitam; est usus
              legentis in iis qui facit eorum claritatem. Investigationes
              demonstraverunt lectores legere me lius quod ii legunt saepius.
              Claritas est etiam processus dynamicus, qui sequitur mutationem
              consuetudium lectorum. Mirum est notare quam littera gothica, quam
              nunc putamus parum claram, anteposuerit litterarum formas
              humanitatis per seacula quarta decima et quinta decima. Eodem modo
              typi, qui nunc nobis videntur parum clari, fiant sollemnes in
              futurum.
            </p>
          </Section>
          <Section title='Buttons'>
            <h4>Primary</h4>
            <Button style={buttonStyle}>Join our community</Button>
            <Button style={buttonStyle} disabled>
              Join our community - disabled
            </Button>
            <br />
            <br />
            <h4>Alternative</h4>
            <ButtonBackground>
              <Button style={buttonStyle} alternative>
                Join our community
              </Button>
            </ButtonBackground>
            <ButtonBackground>
              <Button style={buttonStyle} alternative disabled>
                Join our community - disabled
              </Button>
            </ButtonBackground>
          </Section>
          <Section title='Lists'>
            <h3>Unordered</h3>
            <UnorderedList
              list={[
                'We offer various services, see the relevant one for your business.',
                'We offer various services, see the relevant one for your business.',
                'We offer various services, see the relevant one for your business.',
              ]}
            />
            <h3>Ordered</h3>
            <OrderedList
              list={[
                'We offer various services, see the relevant one for your business.',
                'We offer various services, see the relevant one for your business.',
                'We offer various services, see the relevant one for your business.',
              ]}
            />
          </Section>
          <Section title='Fields'>
            <InputContainer>
              <h3>Input</h3>
              <Input
                label='First Name*'
                placeholder="Business owner's first name"
              />
              <Separator />
              <Input
                errorMessage='This field is required'
                label='Error state'
                placeholder="Business owner's first name"
              />
              <Separator />
              <h3>Dropdown</h3>
              <Select
                label='Example title'
                placeholder='Select from the list'
                options={['Option 1', 'Option 2', 'Option 3', 'Option 4']}
              />
              <Separator />
              <h3>Radio</h3>
              <RadioGroup
                name='test1'
                label='Are you a New Zealand citizen?'
                options={['Yes', 'No']}
              />
              <Separator />
              <RadioGroup
                name='test2'
                label='Horizontal radio buttons'
                options={['Mr.', 'Miss', 'Ms.', 'Mrs.']}
                isHorizontal
              />
              <Separator />
              <DateInput label='Date Input' />
            </InputContainer>
          </Section>
        </Container>
      </div>
    </Layout>
  )
}

export default StyleGuide
