import * as React from 'react'
import styled from 'styled-components'
import Person from './person'

const largeSquiggle = require('../../assets/large-squiggle.svg') as string

interface PersonType {
  id: string
  name: string
  role: string
  bio: {
    bio: string
  }
  image: {
    fixed: {
      src: string
    }
  }
  facebook?: string
  linkedIn?: string
}

interface Props {
  title: string
  people: Array<PersonType>
}

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4.4rem;
`

const Title = styled.h2`
  margin-bottom: 0.5rem;
`

const Squiggle = styled.img`
  width: 7.4rem;
`

const PeopleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const PeopleSection = (props: Props): JSX.Element => {
  return (
    <React.Fragment>
      <TitleContainer>
        <Title>{props.title}</Title>
        <Squiggle src={largeSquiggle} />
      </TitleContainer>
      <PeopleContainer>
        {props.people.map((person) => {
          return (
            <Person
              key={person.id}
              image={person.image}
              name={person.name}
              role={person.role}
              bio={person.bio.bio}
              facebook={person.facebook}
              linkedIn={person.linkedIn}
            />
          )
        })}
      </PeopleContainer>
    </React.Fragment>
  )
}

export default PeopleSection
