// biome-ignore lint/suspicious/noExplicitAny: <explanation>
declare let google: any

import React, { ReactNode, ChangeEvent } from 'react'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import styled from 'styled-components'
import theme from '../../../atoms/theme'
import 'react-google-places-autocomplete/dist/assets/index.css'
import ErrorMessage from '../../../atoms/error-message'

const poweredByGoogle =
  require('../../../../assets/powered_by_google_on_white_hdpi.png') as string

interface InputProps {
  label: string
  placeholder: string
  initialValue?: string
  onSelect?: (place: {
    street: string
    province: string
    city: string
    zip: string
    country: string
    formattedAddress: string
  }) => void
  errorMessage?: string
  onBlur?: (value: string, key: string) => void
}

interface StyleProps {
  errorMessage?: string
}

interface AddressComponent {
  long_name: string
  types: Array<string>
}

interface PlaceDetails {
  address_components: Array<AddressComponent>
}

interface GooglePlace {
  place_id: string
}

const Label = styled.label`
  display: block;
  font-family: ${theme.fonts.secondary};
  font-size: 1.8rem;
  padding-bottom: 1.6rem;
`

const Container = styled.div`
  flex: 1;
  width: 50%;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    width: 100%;
  }
  #-google-places-autocomplete-suggestion--4 {
    background: url(${poweredByGoogle});
    background-size: 12rem;
    background-repeat: no-repeat;
    background-position: 95% 95%;
    padding-bottom: 5rem;
    margin-bottom: 1rem;
  }
`

const CustomInput = styled.input`
  border: ${(props: StyleProps): string =>
    `2px solid ${
      props.errorMessage ? theme.colours.danger1 : theme.colours.black
    }`};
  border-radius: 0.4rem;
  height: 4rem;
  font-family: ${theme.fonts.secondary};
  font-size: 1.8rem;
  padding-left: 1.6rem;
  width: calc(100% - 2rem);
  box-shadow: none;
  &::placeholder {
    font-size: 1.6rem;
  }
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    width: 100%;
  }
`

const getComponentOfAddress = (
  placeDetails: PlaceDetails,
  componentName: string,
): string => {
  const addressComponent = placeDetails.address_components.find((component) => {
    return component.types.includes(componentName)
  })
  return addressComponent ? addressComponent.long_name : ''
}

const GoogleAutocomplete = (props: InputProps): JSX.Element => {
  const handleSelectPlace = async (place: GooglePlace): Promise<void> => {
    const map = new google.maps.Map(document.getElementById('map'))

    const service = new google.maps.places.PlacesService(map)

    service.getDetails({ placeId: place.place_id }, (placeDetails) => {
      const street = `${getComponentOfAddress(
        placeDetails,
        'street_number',
      )} ${getComponentOfAddress(placeDetails, 'route')}`
      const province = getComponentOfAddress(placeDetails, 'locality')
      const city = getComponentOfAddress(
        placeDetails,
        'administrative_area_level_1',
      )
      const zip = getComponentOfAddress(placeDetails, 'postal_code')
      const country = getComponentOfAddress(placeDetails, 'country')
      const formattedAddress = placeDetails.formatted_address

      if (props.onSelect) {
        props.onSelect({
          street,
          province,
          city,
          zip,
          country,
          formattedAddress,
        })
      }
    })
  }

  const suggestionsStyles = {
    suggestion: {
      fontSize: '1.8rem',
      fontFamily: theme.fonts.secondary,
    },
  }

  return (
    <Container>
      <div id='map' />
      <React.Fragment>
        <Label>{props.label}</Label>
        <GooglePlacesAutocomplete
          renderInput={(innerProps: unknown): ReactNode => (
            <CustomInput
              {...innerProps}
              onBlur={(event: ChangeEvent<HTMLInputElement>): void => {
                if (props.onBlur) {
                  props.onBlur(event.target.value, 'googleAddress')
                }
              }}
            />
          )}
          initialValue={props.initialValue}
          placeholder={props.placeholder}
          onSelect={handleSelectPlace}
          suggestionsStyles={suggestionsStyles}
          autocompletionRequest={{
            componentRestrictions: {
              country: ['nz'],
            },
            types: ['address'],
          }}
        />
        {props.errorMessage && (
          <ErrorMessage>{props.errorMessage}</ErrorMessage>
        )}
      </React.Fragment>
    </Container>
  )
}

export default GoogleAutocomplete
