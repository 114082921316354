import * as React from 'react'
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps'
import { compose, withProps } from 'recompose'

interface Props {
  center: {
    lat: number
    lng: number
  }
  zoom?: number
}

const GoogleMapsComponent = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '31rem', width: '100%' }} />,
    mapElement: <div style={{ height: '100%' }} />,
  }),
  withScriptjs,
  withGoogleMap,
)((props: Props): JSX.Element => {
  return (
    <GoogleMap defaultCenter={props.center} defaultZoom={props.zoom || 16}>
      <Marker position={props.center} />
    </GoogleMap>
  )
})

export default GoogleMapsComponent
