import * as React from 'react'
import theme from '../theme'

interface Props {
  active?: boolean
  colour?: string
}

const LetterIcon = (props: Props): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='24'
      viewBox='0 0 25 24'
    >
      <title>Letter</title>
      <path
        fill={
          props.active
            ? theme.colours.quaternary
            : props.colour
              ? props.colour
              : theme.colours.white
        }
        fillRule='evenodd'
        d='M20.437 3h-16c-1.7 0-3 1.3-3 3v12c0 1.7 1.3 3 3 3h16c1.7 0 3-1.3 3-3V6c0-1.7-1.3-3-3-3zm-16 2h16c.4 0 .7.2.9.6l-8.9 6.2-8.9-6.2c.2-.4.5-.6.9-.6zm16 14h-16c-.6 0-1-.4-1-1V7.9l8.4 5.9c.2.1.4.2.6.2.2 0 .4-.1.6-.2l8.4-5.9V18c0 .6-.4 1-1 1z'
      />
    </svg>
  )
}

export default LetterIcon
