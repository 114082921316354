import * as React from 'react'
import styled from 'styled-components'
import FacebookIcon from '../atoms/graphics/facebook-icon'
import LinkedinIcon from '../atoms/graphics/linkedin-icon'
import theme from '../atoms/theme'

const PersonContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0
    ${(props: Props): string =>
      props.fullWidth ? '100%' : 'calc(33.33% - 6.4rem)'};
  max-width: ${(props: Props): string => (props.fullWidth ? '100%' : '33.33%')};
  margin-bottom: 6.4rem;
  @media only screen and ${theme.breakpoints.toMediumScreen} {
    flex: 1 0 100%;
    max-width: 100%;
  }
  &:not(:last-child) {
    margin-right: 3.2rem;
  }
`

const Image = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 1.2rem;
  border-radius: 50%;
  border: 0.4rem solid ${theme.colours.quaternary};
`

const Name = styled.h3`
  color: ${theme.colours.secondary};
  margin-bottom: 0.8rem;
`

const Role = styled.h6`
  text-transform: uppercase;
  color: ${theme.colours.secondary};
  margin-bottom: 0;
`

const Links = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  svg {
    margin-right: 1.2rem;
    &:hover {
      path {
        fill: ${theme.colours.secondaryHover};
      }
    }
  }
`

interface Props {
  image: {
    fixed: {
      src: string
    }
  }
  name: string
  role: string
  bio: string
  facebook?: string
  linkedIn?: string
  fullWidth?: boolean
}

const Person = (props: Props): JSX.Element => {
  return (
    <PersonContainer {...props}>
      <Image src={props?.image?.fixed?.src} />
      <Name>{props.name}</Name>
      <Role>{props.role}</Role>
      <p>{props.bio}</p>
      <Links>
        {props.linkedIn && (
          <a href={props.linkedIn}>
            <LinkedinIcon colour={theme.colours.secondary} />
          </a>
        )}
        {props.facebook && (
          <a href={props.facebook}>
            <FacebookIcon colour={theme.colours.secondary} />
          </a>
        )}
      </Links>
    </PersonContainer>
  )
}

export default Person
