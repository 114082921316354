import * as React from 'react'
import theme from '../theme'

interface Props {
  active?: boolean
  colour?: string
}

const YoutubeIcon = (props: Props): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='24'
      viewBox='0 0 25 24'
    >
      <title>Youtube</title>
      <path
        fill={
          props.active
            ? theme.colours.quaternary
            : props.colour
              ? props.colour
              : theme.colours.white
        }
        fillRule='evenodd'
        d='M23.948 6.177l.014.065c.328 1.82.487 3.666.476 5.504.006 1.821-.153 3.64-.49 5.497-.329 1.313-1.335 2.35-2.652 2.723-.76.203-2.242.328-4.327.407-.992.038-2.054.063-3.114.076-.372.005-.716.008-1.026.01l-.391.001-.392-.001c-.31-.002-.654-.005-1.025-.01-1.061-.013-2.122-.038-3.114-.076-2.085-.08-3.568-.204-4.333-.408-1.276-.35-2.276-1.34-2.66-2.707-.329-1.82-.488-3.666-.476-5.503-.01-1.835.15-3.667.49-5.538.328-1.313 1.335-2.35 2.651-2.723.76-.203 2.243-.328 4.328-.407.992-.038 2.053-.063 3.114-.076.371-.005.716-.008 1.025-.01L12.438 3l.39.001 1.025.009c1.06.012 2.121.034 3.113.069 2.078.072 3.557.186 4.344.379 1.303.368 2.31 1.406 2.638 2.72zM20.8 5.391c-.565-.137-1.981-.246-3.905-.313-.974-.034-2.02-.056-3.066-.068-.366-.005-.706-.007-1.011-.009L12.438 5l-.38.001c-.306.002-.646.005-1.012.01-1.045.013-2.09.037-3.063.074-1.917.073-3.33.192-3.873.337-.614.174-1.087.662-1.228 1.215-.304 1.686-.453 3.396-.444 5.12-.011 1.725.138 3.447.422 5.05.17.598.641 1.064 1.236 1.227.557.149 1.97.268 3.887.34.973.038 2.018.062 3.063.075.366.005.706.008 1.011.01l.38.001.381-.001c.305-.002.645-.005 1.011-.01 1.045-.013 2.09-.037 3.064-.074 1.917-.073 3.33-.192 3.873-.337.613-.174 1.087-.662 1.228-1.215.301-1.673.45-3.37.444-5.08.01-1.712-.136-3.423-.438-5.11-.162-.604-.63-1.08-1.2-1.242zM10.682 15.89c-.667.38-1.494-.102-1.494-.869V8.48c0-.767.827-1.248 1.494-.87l5.75 3.27c.674.384.674 1.356 0 1.74l-5.75 3.27zm3.233-4.139l-2.727-1.55v3.1l2.727-1.55z'
      />
    </svg>
  )
}

export default YoutubeIcon
