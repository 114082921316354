import * as React from 'react'
import styled from 'styled-components'
import type { ContentfulCtaBlock } from '../../generated/graphql-types'
import theme from '../atoms/theme'
import Container from '../container'
import Button from '../molecules/button'
import { ContentBlockWrapper } from '../molecules/content-blocks'
import { FullWidthContainer } from './page-hero-slider'

const TextureSrc = require('../../assets/texture.svg') as string

type CtaBlockProps = {
  block: ContentfulCtaBlock
}

const CtaBlockWrapper = styled.div`
  // background-color: ${theme.colours.secondary};
  //fill:#fff;
  // make the background pattern 10% transparent
  //background-size:230px 230px;
  //background-repeat:repeat;
  background-image:url(${TextureSrc});
`

const CtaBlockTitle = styled.h2`
  font-size: 4rem;
  color: ${theme.colours.white};
  font-weight: 900;
  font-family: ${theme.fonts.primaryBlack};
  line-height: 140%;
  margin-bottom: 4rem;
`

const StyledContainer = styled.div`
  text-align: center;
  padding: 12rem 0;
  max-width: 80rem;
  margin: 0 auto;
`

const CtaBlock = (props: CtaBlockProps): JSX.Element => {
  const { block } = props
  const { title, buttons } = block
  return (
    <ContentBlockWrapper>
      <FullWidthContainer>
        <CtaBlockWrapper>
          <Container>
            <StyledContainer>
              <CtaBlockTitle>{title}</CtaBlockTitle>
              {buttons?.map((button) => (
                <Button
                  key={`${button.id}-${button.url}`}
                  href={button.url}
                  visualType={button.type}
                  visualStyle={button.style}
                >
                  {button.label}
                </Button>
              ))}
            </StyledContainer>
          </Container>
        </CtaBlockWrapper>
      </FullWidthContainer>
    </ContentBlockWrapper>
  )
}

export default CtaBlock
