import get from 'lodash/get'
import React, { useState, ChangeEvent } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import styled from 'styled-components'
import isEmail from 'validator/lib/isEmail'
import Input from '../atoms/input'
import RadioGroup from '../atoms/radio-group'
import RowContainer from '../atoms/row'
import Select from '../atoms/select'
import Separator from '../atoms/separator'
import TextArea from '../atoms/text-area'
import theme from '../atoms/theme'
import Button from '../molecules/button'

const SALESFORCE_TOPIC_IDS = {
  Complaint: '0120K0000015Xi3',
  Feedback: '0120K0000015Xi4',
  Query: '0120K0000015Xi5',
}

const FormContainer = styled.div`
  margin-top: 4rem;
  margin-right: 8.4rem;
  flex: 1;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    margin-right: 0;
    width: 100%;
  }
`

const RequiredFieldLabel = styled.label`
  margin-bottom: 2.4rem;
  display: block;
`

const ReCAPTCHAContainer = styled.div`
  margin-bottom: 2rem;
`

interface Props {
  location: {
    state: {
      topic?: string
    }
  }
}

const ContactForm = (props: Props): JSX.Element => {
  const defaultTopic = get(props, 'location.state.topic', '')

  const initialState = {
    topic: defaultTopic,
    title: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    subject: '',
    description: '',
  }

  const [formFields, setFormFields] = useState(initialState)
  const [selectedTopicId, setSelectedTopicId] = useState(
    defaultTopic ? SALESFORCE_TOPIC_IDS[defaultTopic] : null,
  )
  const [errors, setErrors] = useState(
    Object.assign({}, initialState, { topic: '' }),
  )
  const [reCaptchaTicked, setReCaptchaTicked] = useState(false)

  const validateForm = (): boolean => {
    const {
      topic,
      title,
      firstName,
      lastName,
      phone,
      email,
      subject,
      description,
    } = formFields

    const areRequiredFieldsValid = Boolean(
      topic &&
        title &&
        firstName &&
        lastName &&
        email &&
        phone &&
        subject &&
        description,
    )

    const isEmailValid = isEmail(email)
    const recaptchaCheck = !process.env.GATSBY_RECAPTCHA_KEY || reCaptchaTicked

    return areRequiredFieldsValid && isEmailValid && recaptchaCheck
  }

  const validateRequiredFieldOnBlur = (value, errorKey): void => {
    if (!value) {
      setErrors(
        Object.assign({}, errors, { [errorKey]: 'This field is required' }),
      )
    } else {
      setErrors(Object.assign({}, errors, { [errorKey]: '' }))
    }
  }

  const validateEmail = (value): void => {
    let errorMessage = ''
    if (!value) {
      errorMessage = 'This field is required'
    } else if (!isEmail(value)) {
      errorMessage = 'Invalid email address'
    }
    setErrors(Object.assign({}, errors, { email: errorMessage }))
  }

  const handleTopicChange = (option): void => {
    if (option) {
      setFormFields(Object.assign({}, formFields, { topic: option.value }))
      setSelectedTopicId(SALESFORCE_TOPIC_IDS[option.value])
    } else {
      setFormFields(Object.assign({}, formFields, { topic: '' }))
    }
  }

  const returnUrl = process.env.GATSBY_WEBSITE_URL || ''

  return (
    <FormContainer>
      <meta httpEquiv='Content-type' content='text/html; charset=UTF-8' />
      <h2>
        Have a question, feedback or inquired about Community Sponsorship, Write
        to us!
      </h2>
      <form
        action='https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8'
        method='POST'
      >
        <input type='hidden' name='orgid' value='00D28000001DwZu' />
        <input
          type='hidden'
          name='retURL'
          value={`${returnUrl}/contact-us-thank-you/`}
        />
        <RowContainer data-test-id='topic-select-container'>
          <Select
            onChange={handleTopicChange}
            defaultValue={formFields.topic}
            label='Topic*'
            name='topic-select'
            placeholder='Choose topic'
            options={['Complaint', 'Feedback', 'Query']}
            errorMessage={errors.topic}
            onBlur={(value: string): void =>
              validateRequiredFieldOnBlur(value, 'topic')
            }
          />
        </RowContainer>
        <RowContainer>
          <RadioGroup
            name='salutation'
            label='Title*'
            onChange={(e): void =>
              setFormFields(
                Object.assign({}, formFields, { title: e.target.value }),
              )
            }
            isHorizontal
            options={['Mr.', 'Miss', 'Ms.', 'Mrs.']}
            errorMessage={errors.title}
            onBlur={(isChecked: boolean): void =>
              validateRequiredFieldOnBlur(isChecked, 'title')
            }
          />
        </RowContainer>
        <RowContainer>
          <Input
            onChange={(e): void =>
              setFormFields(
                Object.assign({}, formFields, { firstName: e.target.value }),
              )
            }
            label='First Name*'
            placeholder='First name'
            name='00N0K00000LV0Ii'
            errorMessage={errors.firstName}
            onBlur={(e: ChangeEvent<HTMLInputElement>): void =>
              validateRequiredFieldOnBlur(e.target.value, 'firstName')
            }
          />
          <Separator />
          <Input
            onChange={(e): void =>
              setFormFields(
                Object.assign({}, formFields, { lastName: e.target.value }),
              )
            }
            label='Last Name*'
            placeholder='Last name'
            name='00N0K00000LV0Ij'
            errorMessage={errors.lastName}
            onBlur={(e: ChangeEvent<HTMLInputElement>): void =>
              validateRequiredFieldOnBlur(e.target.value, 'lastName')
            }
          />
        </RowContainer>
        <RowContainer>
          <Input
            onChange={(e): void =>
              setFormFields(
                Object.assign({}, formFields, { email: e.target.value }),
              )
            }
            label='Email*'
            placeholder='Email address'
            name='email'
            errorMessage={errors.email}
            onBlur={(e: ChangeEvent<HTMLInputElement>): void =>
              validateEmail(e.target.value)
            }
          />
          <Separator />
          <Input
            onChange={(e): void =>
              setFormFields(
                Object.assign({}, formFields, { phone: e.target.value }),
              )
            }
            label='Phone Number*'
            placeholder='Insert your number e.g. 0212345678'
            subText='(Numbers only. No spaces or any other characters)'
            name='phone'
            type='number'
            errorMessage={errors.phone}
            onBlur={(e: ChangeEvent<HTMLInputElement>): void =>
              validateRequiredFieldOnBlur(e.target.value, 'phone')
            }
          />
        </RowContainer>
        <RowContainer>
          <Input
            onChange={(e): void =>
              setFormFields(
                Object.assign({}, formFields, { subject: e.target.value }),
              )
            }
            label='Subject*'
            placeholder='What is your inquiry about?'
            name='subject'
            errorMessage={errors.subject}
            onBlur={(e: ChangeEvent<HTMLInputElement>): void =>
              validateRequiredFieldOnBlur(e.target.value, 'subject')
            }
          />
        </RowContainer>
        <RowContainer>
          <TextArea
            onChange={(e): void =>
              setFormFields(
                Object.assign({}, formFields, { description: e.target.value }),
              )
            }
            label='Description*'
            placeholder='How can we help you?'
            name='description'
            errorMessage={errors.description}
            onBlur={(e: ChangeEvent<HTMLTextAreaElement>): void =>
              validateRequiredFieldOnBlur(e.target.value, 'description')
            }
          />
        </RowContainer>
        <RequiredFieldLabel>*Mandatory fields</RequiredFieldLabel>

        {/* Hidden fields needed to submit to Salesforce */}
        <div style={{ display: 'none' }}>
          <label htmlFor='recordType'>Topic*</label>
          <select
            value={selectedTopicId}
            id='recordType'
            name='recordType'
            required
          >
            <option value=''>--None--</option>
            <option value='0120K0000015Xi3'>Complaint</option>
            <option value='0120K0000015Xi4'>Feedback</option>
            <option value='0120K0000015Xi5'>Query</option>
          </select>
          <br />
          <label htmlFor='00N0K00000LV0Ik'>Title*:</label>
          <select
            value={formFields.title}
            id='00N0K00000LV0Ik'
            name='00N0K00000LV0Ik'
            title='Title'
            required
          >
            <option value=''>--None--</option>
            <option value='Mr.'>Mr.</option>
            <option value='Miss'>Miss</option>
            <option value='Ms.'>Ms.</option>
            <option value='Mrs.'>Mrs.</option>
          </select>
          <br />
          <input type='hidden' id='external' name='external' value='1' />
          <br />
        </div>
        {process.env.GATSBY_RECAPTCHA_KEY && (
          <ReCAPTCHAContainer>
            <ReCAPTCHA
              sitekey={process.env.GATSBY_RECAPTCHA_KEY}
              onChange={(value): void => setReCaptchaTicked(value)}
            />
          </ReCAPTCHAContainer>
        )}

        <Button type='submit' disabled={!validateForm()}>
          Send
        </Button>
      </form>
    </FormContainer>
  )
}

export default ContactForm
