import React, { useState } from 'react'
import ReactSelect from 'react-select'
import styled from 'styled-components'
import ErrorMessage from './error-message'
import theme from './theme'

interface Option {
  label: string
  value: string
}

interface SelectProps {
  placeholder: string
  label: string
  name?: string
  options: Array<string>
  defaultValue?: string
  onBlur?: (value: string) => void
  onChange?: (option: Option) => void
  errorMessage?: string
  subText?: string
  isMulti?: boolean
}

const Label = styled.label`
  display: block;
  font-family: ${theme.fonts.secondary};
  font-size: 1.8rem;
  padding-bottom: 1.6rem;
`

const SelectContainer = styled.div`
  flex: 1;
`

const SubText = styled.span`
  font-size: 1.4rem;
  padding-left: 1rem;
`

const customStyles = {
  container: (provided): object => ({
    ...provided,
  }),
  dropdownIndicator: (provided): object => ({
    ...provided,
    color: theme.colours.black,
  }),
  placeholder: (provided): object => ({
    ...provided,
    height: '3rem',
    fontSize: '1.6rem',
    paddingTop: '0.3rem',
  }),
  control: (provided): object => ({
    ...provided,
    border: `0.2rem solid ${theme.colours.black}`,
    borderRadius: '0.4rem',
    height: '4rem',
    '&:hover': {
      borderColor: theme.colours.black,
    },
  }),
  valueContainer: (provided): object => ({
    ...provided,
    height: '100%',
    paddingTop: '0',
    paddingBottom: '0',
  }),
}

const Select = (props: SelectProps): JSX.Element => {
  const [value, setValue] = useState(props.defaultValue)
  const selectOptions = props.options.map((option): object => ({
    label: option,
    value: option,
  }))

  const defaultValue = props.defaultValue
    ? {
        label: props.defaultValue,
        value: props.defaultValue,
      }
    : null

  const handleOnChange = (newValue): void => {
    setValue(newValue)
    if (props.onChange) {
      props.onChange(newValue)
    }
  }

  return (
    <SelectContainer>
      <Label>
        {props.label}
        {props.subText && <SubText> {props.subText}</SubText>}
      </Label>
      <ReactSelect
        inputId={props.name}
        name={props.name}
        placeholder={props.placeholder}
        styles={customStyles}
        defaultValue={defaultValue}
        options={selectOptions}
        isClearable
        onChange={handleOnChange}
        onBlur={props.onBlur ? (): void => props.onBlur(value) : false}
        components={{
          IndicatorSeparator: (): void => null,
        }}
        isMulti={props.isMulti}
      />
      {props.errorMessage && <ErrorMessage>{props.errorMessage}</ErrorMessage>}
    </SelectContainer>
  )
}

export default Select
