import { Link } from 'gatsby'
import { MouseEvent, ReactNode } from 'react'
import * as React from 'react'
import styled from 'styled-components'
import theme from '../atoms/theme'

export type ButtonVisualStyle = 'Primary' | 'Secondary'
export type ButtonVisualType = 'Solid' | 'Outline'

interface ButtonProps {
  children: ReactNode
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void
  active?: boolean
  disabled?: boolean
  alternative?: boolean
  style?: object
  type?: 'button' | 'submit' | 'reset'
  visualStyle?: ButtonVisualStyle
  visualType?: ButtonVisualType
  href?: string
  as?: string | undefined
}

export const StyledButton = styled.button<ButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props): string =>
    props.visualType === 'Outline'
      ? 'transparent'
      : props.visualStyle === 'Secondary'
        ? theme.colours.white
        : theme.colours.quaternary};
  color: ${(props): string =>
    props.visualType === 'Outline'
      ? props.visualStyle === 'Secondary'
        ? theme.colours.white
        : theme.colours.quaternary
      : props.visualStyle === 'Secondary'
        ? theme.colours.darkGrey
        : theme.colours.white};
  border-radius: 0.8rem;
  font-family: ${theme.fonts.primaryBold};
  font-size: 1.6rem;
  //height: 5rem;
  letter-spacing: 0.1rem;
  padding: 1rem 1.8rem;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
  border-width: ${(props): string =>
    props.visualType === 'Outline' ? '1px' : '0'};
  border-color: ${(props): string =>
    props.visualStyle === 'Secondary'
      ? theme.colours.white
      : theme.colours.quaternary};
  border-style: solid;
  cursor: ${(props: ButtonProps): string =>
    props.disabled ? 'default' : 'pointer'};
  &:hover {
    background-color: ${(props): string =>
      props.visualType === 'Outline'
        ? props.visualStyle === 'Secondary'
          ? theme.colours.white
          : theme.colours.quaternaryHover
        : theme.colours.quaternaryHover};
    color: ${(props): string =>
      props.visualType === 'Outline'
        ? props.visualStyle === 'Secondary'
          ? theme.colours.quaternary
          : theme.colours.white
        : theme.colours.white};
  }
  &:active {
    background-color: ${(props): string =>
      props.visualType === 'Outline'
        ? props.visualStyle === 'Secondary'
          ? theme.colours.white
          : theme.colours.quaternaryHover
        : theme.colours.quaternaryHover};
    color: ${(props): string =>
      props.visualType === 'Outline'
        ? props.visualStyle === 'Secondary'
          ? theme.colours.quaternary
          : theme.colours.white
        : theme.colours.white};
  }
  &:disabled {
    background-color: ${theme.colours.gray2};
    color: ${theme.colours.white};
  }
  &:focus {
    outline: 0;
  }
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    width: 100%;
  }
  @media only screen and ${theme.breakpoints.fromMediumScreen} {
    font-size: 1.8rem;
    //height: 6rem;
  }
`

export const AltStyledButton = styled(StyledButton)<ButtonProps>`
  height: 4.4rem;
  line-height: 4.4rem;
  background-color: ${theme.colours.danger1};
  color: ${theme.colours.white};
  &:hover {
    background-color: ${theme.colours.white};
    color: ${theme.colours.danger1};
  }
  &:active {
    background-color: ${theme.colours.white};
    color: ${theme.colours.danger1};
  }
`

const Button = (props: ButtonProps): JSX.Element => {
  const { href, children, alternative, visualType, visualStyle, ...restProps } =
    props

  let processedHref = href
  if (href?.startsWith(process.env.GATSBY_WEBSITE_URL)) {
    processedHref = href.replace(process.env.GATSBY_WEBSITE_URL, '')
  }

  if (processedHref) {
    if (processedHref.startsWith('/')) {
      return alternative ? (
        <AltStyledButton as={Link} to={processedHref} {...restProps}>
          {children}
        </AltStyledButton>
      ) : (
        <StyledButton as={Link} to={processedHref} {...restProps}>
          {children}
        </StyledButton>
      )
    }
    return alternative ? (
      <AltStyledButton as='a' href={processedHref} {...restProps}>
        {children}
      </AltStyledButton>
    ) : (
      <StyledButton as='a' href={processedHref} {...restProps}>
        {children}
      </StyledButton>
    )
  }

  return alternative ? (
    <AltStyledButton {...restProps}>{children}</AltStyledButton>
  ) : (
    <StyledButton {...restProps}>{children}</StyledButton>
  )
}

export default Button
