import * as React from 'react'
import theme from '../theme'

interface Props {
  active?: boolean
  colour?: string
}

const DangerIcon = (props: Props): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='24'
      viewBox='0 0 25 24'
    >
      <title>Danger</title>
      <path
        fill={
          props.active
            ? theme.colours.quaternary
            : props.colour
              ? props.colour
              : theme.colours.white
        }
        fillRule='evenodd'
        d='M3.937 22c-.5 0-1-.1-1.5-.4-1.4-.8-1.9-2.7-1.1-4.1l8.5-14.2c.2-.4.6-.8 1-1 .7-.4 1.5-.5 2.3-.3.8.2 1.4.7 1.9 1.4l8.4 14.1c.3.5.4 1 .4 1.5 0 .8-.3 1.6-.9 2.1-.5.6-1.2.9-2 .9h-17zm7.6-17.6l-8.4 14.1c-.3.5-.1 1.1.4 1.4.1.1.3.1.4.1h16.9c.3 0 .5-.1.7-.3.2-.2.3-.4.3-.7 0-.2 0-.3-.1-.5l-8.4-14.1c-.3-.5-.9-.6-1.4-.4-.2.1-.3.2-.4.4zm1.9 8.6V9c0-.6-.4-1-1-1s-1 .4-1 1v4c0 .6.4 1 1 1s1-.4 1-1zm-.3 4.7c.2-.2.3-.4.3-.7 0-.1 0-.3-.1-.4 0-.1-.1-.2-.2-.3 0 0-.1-.1-.2-.1s-.1-.1-.2-.1-.1 0-.2-.1c-.2 0-.4 0-.6.1-.1 0-.2.1-.3.2-.1.1-.2.2-.2.3 0 .1-.1.2-.1.4 0 .3.1.5.3.7.2.2.4.3.7.3.4 0 .6-.1.8-.3z'
      />
    </svg>
  )
}

export default DangerIcon
