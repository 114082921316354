import { get } from 'lodash'
import * as React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import Layout from '../components/layout'
import Header from '../components/molecules/header'
import RichTextRenderer from './rich-text-renderer'

interface Props {
  location: {
    pathname: string
  }
}

const Container = styled.div`
  margin-top: -4rem;
`

const LegalPage = (props: Props): JSX.Element => {
  const siteTitle = get(props, 'data.site.siteMetadata.title')
  const currentPath = get(props, 'location.pathname')
  const page = get(props, 'pathContext.node')

  return (
    <Layout currentPath={currentPath}>
      <Helmet title={siteTitle} />
      <Header title={page.title} />
      <Container>
        <RichTextRenderer richTextJson={page.body.json} />
      </Container>
    </Layout>
  )
}

export default LegalPage
