import * as React from 'react'
import theme from '../theme'

interface Props {
  small?: boolean
}

const SquiggleSVG = (props: Props): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={`${props.small ? '28' : '74'}`}
      height='7'
      viewBox={`0 0 ${props.small ? '28' : '74'} 7`}
      fill={theme.colours.quaternary}
    >
      <title>Squiggle</title>
      <g fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g transform='translate(-1247 -61)'>
          <g transform='translate(1228 29)'>
            <path d='M88.3748438 35.8042363L83.7496877 32 79.1245315 35.8037232 74.4993754 32 69.8742192 35.8037232 65.2495628 32 60.6244066 35.8042363 55.9992505 32 51.374594 35.8037232 46.7494378 32 42.1242817 35.8042363 37.4991255 32 32.8744691 35.8037232 28.2498126 32 23.6246565 35.8037232 19 32 19 35.1962768 23.6246565 39 28.2498126 35.1962768 32.8744691 39 37.4991255 35.1962768 42.1242817 39 46.7494378 35.1962768 51.374594 39 55.9992505 35.1962768 60.6244066 39 65.2495628 35.1962768 69.8742192 39 74.4993754 35.1962768 79.1245315 39 83.7496877 35.1962768 88.3748438 39 93 35.1962768 93 32z' />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SquiggleSVG
