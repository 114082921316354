import * as React from 'react'
import theme from '../theme'

interface Props {
  active?: boolean
  colour?: string
}

const FacebookIcon = (props: Props): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='24'
      viewBox='0 0 25 24'
    >
      <title>Facebook</title>
      <path
        fill={
          props.active
            ? theme.colours.quaternary
            : props.colour
              ? props.colour
              : theme.colours.white
        }
        fillRule='evenodd'
        d='M18.401 7c.598 0 .997-.4.997-1V2c0-.6-.399-1-.997-1h-2.99C12.12 1 9.427 3.7 9.427 7v2H7.434c-.598 0-.997.4-.997 1v4c0 .6.399 1 .997 1h1.994v7c0 .6.399 1 .997 1h3.988c.598 0 .997-.4.997-1v-7h1.994c.499 0 .897-.3.997-.8l.997-4c.1-.3 0-.6-.2-.9C19 9 18.7 9 18.402 9h-2.99V7h2.99zm-3.988 4h2.692l-.499 2h-2.193c-.598 0-.997.4-.997 1v7h-1.994v-7c0-.6-.399-1-.997-1H8.431v-2h1.994c.598 0 .997-.4.997-1V7c0-2.2 1.795-4 3.988-4h1.994v2H15.41c-1.097 0-1.994.9-1.994 2v3c0 .6.399 1 .997 1z'
      />
    </svg>
  )
}

export default FacebookIcon
