import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import Image from 'gatsby-image'
import * as React from 'react'
import useContentfulImage from '../hooks/useContentfulImage'

const nodeRenderer = (node): JSX.Element => {
  const fluid = useContentfulImage(node.data.target.fields.file['en-US'].url)
  return <Image title={node.data.target.fields.title['en-US']} fluid={fluid} />
}

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: nodeRenderer,
  },
}

export default (props): JSX.Element =>
  documentToReactComponents(props.richTextJson, options) as JSX.Element
