import Img from 'gatsby-image'
import * as React from 'react'
import styled from 'styled-components'
import theme from '../atoms/theme'

interface Props {
  fullWidth?: boolean
  heroImage: {
    fluid
  }
}

const fullWidthStyles = `
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
`

const HeroImage = styled(Img)`
  width: 100%;
  height: 60rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    height: 20rem;
    ${fullWidthStyles}
  }
`

const HeroImageFullWidth = styled(HeroImage)`
  ${fullWidthStyles}
`

const Hero = (props: Props): JSX.Element => {
  return props.fullWidth ? (
    <HeroImageFullWidth fluid={props.heroImage.fluid} />
  ) : (
    <HeroImage fluid={props.heroImage.fluid} />
  )
}

export default Hero
