import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import * as React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import ArrowRightIcon from '../../components/atoms/graphics/arrow-right'
import theme from '../../components/atoms/theme'
import Layout from '../../components/layout'
import Breadcrumbs from '../../components/molecules/breadcrumbs'
import Button from '../../components/molecules/button'
import Header from '../../components/molecules/header'
import Testimonials from '../../components/organisms/testimonials'

const Description = styled.h3`
  max-width: 100%;
  text-align: left;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    text-align: left;
    max-width: 100%;
    margin-bottom: 3.7rem;
    font-size: 1.9rem;
  }
`

const BodyContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const LeftContainer = styled.div`
  width: 48%;
  padding-right: 2.5rem;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    width: 100%;
    padding-right: 0;
    order: 2;
  }
`

const ImageContainer = styled.div`
  width: 48%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    width: 100%;
    order: 1;
    height: 23rem;
    margin-bottom: 2.5rem;
  }
`

const Line = styled.div`
  height: 1px;
  background-color: ${theme.colours.gray3};
  width: 100%;
  margin: 7rem 0;
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    margin: 2.5rem 0;
    order: 3;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const StyledButton = styled(Button)`
  padding: 10px 24px;
  height: 4.4rem;
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  min-width: unset;
  span {
    padding-right: 0.5rem;
  }
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    justify-content: center;
  }
`

const StyledImage = styled.img`
  object-fit: contain;
`

const TestimonialsContainer = styled.div`
  @media only screen and ${theme.breakpoints.toLargeScreen} {
    order: 3;
  }
`

const ContentContainer = styled.div`
  ul {
    p {
      margin: 0;
    }
  }
`

interface Props {
  location: {
    pathname: string
  }
}

const DigitalPlatform = (props: Props): JSX.Element => {
  const currentPath = get(props, 'location.pathname')
  const siteTitle = get(props, 'data.site.siteMetadata.title')
  const allPages = get(props, 'data.allContentfulPage.nodes')
  const currentPage = allPages.find((page) => get(page, 'slug') === currentPath)
  const description = currentPage ? currentPage.description.description : null
  const testimonials = get(
    props,
    'data.allContentfulTestimonialDigitalPlatform.nodes',
  )
  const digitalPlatform = get(
    props,
    'data.allContentfulRichTextOnLeftImageOnRightBlock.nodes[0]',
  )

  return (
    <Layout currentPath={currentPath}>
      <Helmet title={siteTitle} />
      <Header title={currentPage.title}>
        <Breadcrumbs
          crumbs={[
            {
              label: 'Services',
              path: '/services/',
            },
            {
              label: currentPage.title,
              isActive: true,
            },
          ]}
        />
      </Header>
      <BodyContainer>
        <LeftContainer>
          {description && <Description>{description}</Description>}
          {digitalPlatform.body && (
            <ContentContainer>
              {documentToReactComponents(digitalPlatform.body.json)}
            </ContentContainer>
          )}
          {digitalPlatform.buttonLabel && (
            <StyledLink
              to={digitalPlatform.buttonUrl ? digitalPlatform.buttonUrl : '#'}
            >
              <StyledButton>
                <span>{digitalPlatform.buttonLabel}</span> <ArrowRightIcon />
              </StyledButton>
            </StyledLink>
          )}
        </LeftContainer>
        <ImageContainer>
          {digitalPlatform.image ? (
            <StyledImage
              src={digitalPlatform.image.file.url}
              alt={digitalPlatform.image.title}
            />
          ) : (
            'Digital platform screenshot'
          )}
        </ImageContainer>
        <Line />
        <TestimonialsContainer>
          <Testimonials testimonials={testimonials} />
        </TestimonialsContainer>
      </BodyContainer>
    </Layout>
  )
}

export default DigitalPlatform

export const pageQuery = graphql`
  query DigitalPlatformQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulPage {
      nodes {
        title
        slug
        description {
          description
        }
      }
    }
    #    allContentfulTestimonialDigitalPlatform {
    #      nodes {
    #        personName
    #        personRole
    #        text {
    #          json
    #        }
    #      }
    #    }
    allContentfulRichTextOnLeftImageOnRightBlock(
      filter: { id: { eq: "128ef41a-f829-5642-8ca5-5049dd90c805" } }
    ) {
      nodes {
        buttonLabel
        buttonUrl
        body {
          json
        }
        image {
          title
          file {
            url
          }
        }
      }
    }
  }
`
