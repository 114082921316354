export type Ratio = {
  x: number
  y: number
}

type ContentfulImageWithFocalPoint = {
  image?: {
    title?: string
    file?: {
      details?: {
        image?: {
          width?: number
          height?: number
        }
      }
    }
    fluid?: {
      src?: string
      srcSetWebp?: string
    }
  }
  focalPoint?: {
    focalPoint?: {
      x?: number
      y?: number
    }
  }
}

/**
 * Return a focal ratio for this image.
 * Note that if an invalid image is requested, it will always return a center (50-50) ratio by default
 *
 * @param imageWithFocalPoint
 * @return Ratio with x and y properties
 */
const GetFocalRatio = (
  imageWithFocalPoint?: ContentfulImageWithFocalPoint,
): Ratio => {
  const focalPoint = imageWithFocalPoint?.focalPoint?.focalPoint
  const image = imageWithFocalPoint?.image

  // There is either no image, or no focal point, so default to center
  if (
    !image ||
    !focalPoint ||
    focalPoint.x === null ||
    focalPoint.y === null ||
    !image.file.details.image.width ||
    !image.file.details.image.height
  ) {
    return { x: 50, y: 50 }
  }

  // Third case, we have image with focal point
  return {
    x: (focalPoint.x / image.file.details.image.width) * 100,
    y: (focalPoint.y / image.file.details.image.height) * 100,
  }
}

export default GetFocalRatio
