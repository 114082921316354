import * as React from 'react'
import styled from 'styled-components'
import theme from './theme'

interface UnorderedListProps {
  list: Array<string>
}

const CustomUnorderedList = styled.ul`
  list-style: none;
`

const CustomBulletPoint = styled.li`
  margin: 0;
  margin-bottom: 1rem;
  padding-left: 2rem;
  position: relative;

  &:after {
    content: '';
    height: 1.1rem;
    width: 1.1rem;
    background: ${theme.colours.quaternary};
    display: block;
    position: absolute;
    transform: rotate(45deg);
    top: 0.8rem;
    left: 0;
  }
`

const UnorderedList = (props: UnorderedListProps): JSX.Element => {
  return (
    <CustomUnorderedList>
      {props.list.map((item) => (
        <CustomBulletPoint key={item}>{item}</CustomBulletPoint>
      ))}
    </CustomUnorderedList>
  )
}

export default UnorderedList
